import React, { useState } from 'react';
import { Modal, Button } from 'react-bootstrap';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faFacebook,
    faTwitter,
    faWhatsapp,
    faLinkedin,
    faPinterest
} from '@fortawesome/free-brands-svg-icons';


import {
    FacebookShareButton,
    TwitterShareButton,
    EmailShareButton,
    WhatsappShareButton,
    LinkedinShareButton,
    PinterestShareButton
} from 'react-share';


const ShareModal = ({ show, onClose }) => {
    const shareUrl = window.location.href; // URL to share, can be the current page or a result page
    const title = 'Check out my pet\'s breed prediction!'; // Title for the shared content

    return (
        <Modal show={show} onHide={onClose}>
            <Modal.Header closeButton>
                <Modal.Title>Discover Your Pet's Breed with AI Breed Detector!</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <p>I just discovered my pet's breed using this awesome Breed Detector!</p>
                <div className="d-flex justify-content-around">
                    {/* Facebook */}
                    <FacebookShareButton url={shareUrl} quote={title}>
                        <FontAwesomeIcon icon={faFacebook} />
                        <span> Facebook</span>
                    </FacebookShareButton>

                    {/* Twitter */}
                    <TwitterShareButton url={shareUrl} title={title}>
                        <FontAwesomeIcon icon={faTwitter} />
                        <span> Twitter</span>
                    </TwitterShareButton>

                    {/* WhatsApp */}
                    <WhatsappShareButton url={shareUrl} title={title}>
                        <FontAwesomeIcon icon={faWhatsapp} />
                        <span> WhatsApp</span>
                    </WhatsappShareButton>

                    {/* LinkedIn */}
                    <LinkedinShareButton url={shareUrl} title={title}>
                        <FontAwesomeIcon icon={faLinkedin} />
                        <span> LinkedIn</span>
                    </LinkedinShareButton>


                </div>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={onClose}>
                    Close
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default ShareModal;
