import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Card, Modal, Button } from 'react-bootstrap';

function Termsofuse() {


    return (
        <Container>
            <Row>
                <Container>
                    <h1>Terms of use</h1>

                    <p>These Terms of Use apply to the mobile application named "Pets Home App" (the "App"). We
                        permitsTerms and any applicable rules applied by Apple Inc. ("App Store Rules") and Google Inc.
                        ("Play Store Rules") with respect to the use of
                        their App Store and Play Store respectively located at https://www.apple.com/itunes ("App
                        Store") and https://play.google.com/store ("Play Store"). You ("you," the App user) may only
                        utilize the App in accordance with these Terms.
                    </p>

                    <p >We have zero tolerance for objectionable content of any kind or abusive
                        users using the App. Abuse will be defined in a broad way that includes any types of threats or
                        implied threats, any dishonest behavior intended to compromise
                        another user, or any actions on the App that is unwanted by the other person. We will closely
                        monitor App usage, content, and reports of bad behavior. In addition to users having the ability
                        to block a user, We reserves the right
                        to warn or deactivate users’ accounts on its own determination of a single case of abusive
                        behavior or any pattern of inappropriate behavior.</p>


                    <p> We are not responsible if the information that we make available on this app is not accurate or
                        complete. Any reliance upon the material on this App shall be at your own risk. You agree that
                        it is your responsibility to monitor any
                        changes to the material and the information contained on this App.</p>

                    <p>Upon registering with Pets Home, you agree to receive essential communication directly to your
                        email. This includes a welcome email upon registration and notifications for new messages,
                        particularly when your in-app notifications are disabled. These emails are part of our
                        commitment to ensure you stay informed and connected without missing important updates related
                        to your Pets Home account.</p>


                    <p>
                        All copyright, trademarks and other intellectual property rights in all text, images and other
                        materials on this App are the property of Pets Home App or are included with the permission of
                        the relevant owner.
                    </p>




                    <p>Your use of this App is at your own and exclusive risk.</p>

                    <p> THIS App IS PROVIDED TO YOU ON AN “AS IS” AND “AS AVAILABLE” BASIS AND, CONSEQUENTLY, Pets Home
                        App MAKES NO WARRANTIES OF ANY KIND, WHETHER EXPRESS, IMPLIED, STATUTORY OR OTHERWISE, INCLUDING
                        BUT NOT LIMITED TO THE IMPLIED WARRANTIES
                        OF MERCHANTABILITY OR SATISFACTORY QUALITY AND FITNESS FOR A PARTICULAR PURPOSE) INCLUDING
                        WARRANTIES OR REPRESENTATIONS THAT MATERIAL ON THIS App WILL BE COMPLETE, ACCURATE, RELIABLE,
                        TIMELY, NON-INFRINGING TO THIRD PARTIES, THAT
                        ACCESS TO THIS App WILL BE UN-INTERRUPTED OR ERROR-FREE OR FREE FROM VIRUSES, THAT THIS App WILL
                        BE SECURE, THAT ANY ADVICE OR OPINION OBTAINED FROM Pets Home App THROUGH THIS App IS ACCURATE
                        OR TO BE RELIED UPON AND ANY REPRESENTATIONS
                        OR WARRANTIES THERETO ARE ACCORDINGLY EXPRESSLY DISCLAIMED.</p>

                    <p>If you select a paid Service, you must provide us with current, complete, accurate and authorized
                        payment method information (e.g. credit card information). You authorize us to charge your
                        provided payment method for the Services you
                        have selected and for any paid feature(s) that you choose.</p>

                    <p>You may elect one of the following subscription plans and billing options (please note that there
                        might be only one of these options available depending on the Service purchased):</p>

                    <p>
                        A monthly subscription plan (“Monthly Subscription Plan”). The subscription period for the
                        Monthly Subscription Plan will be for one month and will automatically renew (without the need
                        to go through the Services-interface “check-out” or execute a renewal
                        order form) unless you cancel your Monthly Subscription Plan at least three (3) business days
                        prior to the renewal date. You will be billed on or about the same day each month until such
                        time that you cancel. </p>
                    <p>
                        An annual subscription plan (“Annual Subscription Plan”). The subscription period for the Annual
                        Subscription Plan will be for one year and will automatically renew (without the need to go
                        through the Services-interface “check-out” or execute a renewal
                        Order Form) for additional periods equal to one (1) year each year on the anniversary unless you
                        cancel at least three (3) business days prior to your renewal date. You will be billed annually
                        on or about the same day each year
                        until such time that you cancel. </p>

                    <p>
                        If you select the Monthly Subscription Plan, you can switch to the Annual Subscription Plan at
                        any time. If you select the Annual Subscription Plan, you may not change to the Monthly
                        Subscription Plan until the end of the one-year term of your Annual
                        Subscription Plan.</p>

                    <p>
                        Where required by law, you may have a legal right to cancel your subscription, pursuant to
                        Section 20, during the prescribed timeframe of your initial subscription period. If you are
                        entitled to this right by law, your cancellation request will be processed
                        within three (3) business days, and you will receive a prorated refund of any unused prepaid
                        fees. </p>



                    <p>Please note that some jurisdictions may not allow exclusions of implied warranties, so some of
                        these exclusions may not apply to you. Please check your local laws.
                    </p>

                    <p>
                        We reserve the right to restrict, suspend or terminate without notice your access to this App or
                        any feature of this App or any feature or part thereof at any time.</p>


                    <p>
                        Pets Home App and/or any other party involved in the creating, producing or delivering this App
                        on our behalf shall have no liability or any responsibility whatsoever for any direct,
                        incidental, consequential, indirect, special or punitive damages, costs,
                        losses or liabilities whatsoever and howsoever arising out of your access to, use, inability to
                        use, change in content of this App or arising from any other App you access through a link from
                        this App or to the extent permitted
                        by applicable law, from any actions we take or fail to take as a result of any electronic mail
                        messages you send us.
                    </p>

                    <p>
                        Pets Home App shall have no responsibility to maintain the material and services made available
                        on this App or to supply any corrections, updates, or releases in connection therewith. Any
                        material on this App is subject to change without notice.
                    </p>

                    <p>
                        Further, Pets Home App shall have no liability or any responsibility whatsoever for any loss
                        suffered caused by viruses that may infect your computer equipment or other property by reason
                        of your use of, access to or downloading of any material from this
                        App. If you choose to download material from this App you do so at your own risk.
                    </p>

                    <p>
                        You are prohibited from doing any act that Pets Home App in its absolute discretion may deem to
                        be inappropriate and/or would be deemed to be an unlawful act or is prohibited by any laws
                        applicable to this App including but not limited to:
                    </p>

                    <p>Any act that would constitute a breach of either the privacy (including uploading private
                        information without the concerned individual’s consent) or any other of the legal rights of
                        individuals</p>

                    <p>Using this App to defame or libel Pets Home App, its employees or other individuals or acting in
                        such a way that brings into disrepute the good name of Pets Home App</p>

                    <p> This App is located in and operated from the United Kingdom. Your use and access to this App
                        will be governed by the laws of the United States. For information regarding how we treat data
                        collection and the transfer of personally identifiable
                        information from countries or jurisdictions outside the UK, please review our Privacy Policy.
                    </p>

                    <p>We reserve the right to make any changes and corrections to this notice. Please refer to this
                        page from time to time to review these and new additional information.
                    </p>

                </Container>
            </Row>
        </Container>
    );
}

export default Termsofuse;
