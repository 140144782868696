import React, { useState, useContext, useEffect } from 'react';
import { Container, Form, Button, Alert } from 'react-bootstrap';
import { UserAuth, userData } from '../context/AuthContext'; // Import your authentication context
import { db } from '../firebase'; // Import Firebase configurations
import { doc, updateDoc } from 'firebase/firestore';

const Profile = () => {
    const { user, userData } = UserAuth();
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [error, setError] = useState('');
    const [success, setSuccess] = useState('');
    const [accountPhone, setAccountPhone] = useState('');

    useEffect(() => {

        // Initialize the form with the current user data
        if (user) {
            setName(userData.name || '');
            setEmail(userData.email || '');
            setAccountPhone(userData.phone || '');
        }
    }, [user, userData]);

    const validateEmail = (email) => {
        const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(email).toLowerCase());
    };

    const handleSave = async () => {
        setError('');
        setSuccess('');

        // Validate name
        if (!name.trim()) {
            setError('Name cannot be empty.');
            return;
        }

        // Validate email using the validateEmail function
        if (!validateEmail(email)) {
            setError('Please enter a valid email address.');
            return;
        }

        try {
            // Reference to the user's document in Firestore
            const userDocRef = doc(db, 'users', user.uid);

            // Update the user's name and email in Firestore
            await updateDoc(userDocRef, {
                name: name,
                email: email
            });

            // Update success message
            setSuccess('Profile updated successfully.');

            // You can also update the user's context or global state here if needed
            // setUser({ ...user, displayName: name, email: email });
        } catch (error) {
            setError('Failed to update profile. ' + error.message);
        }
    };

    if (!user || !userData) {
        // User or userData not available, or loading state
        return null;
    }

    return (


        <Container>
            <h5>Edit Profile - ({accountPhone})</h5>
            {error && <Alert variant="danger">{error}</Alert>}
            {success && <Alert variant="success">{success}</Alert>}
            <p className='text-right'>
                <a className='btn btn-link ' href=' https://billing.stripe.com/p/login/aEUbMcbpl8Nc4yA3cc'> Manage subscription </a> <br />
                contact us : admin@petshome.app
            </p>

            <Form>
                <Form.Group controlId="formName">
                    <Form.Label>Name</Form.Label>
                    <Form.Control
                        type="text"
                        placeholder="Enter your name"
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                    />
                </Form.Group>

                <Form.Group className='mt-4' controlId="formEmail">
                    <Form.Label>Email</Form.Label>
                    <Form.Control
                        type="email"
                        placeholder="Enter your email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                    />
                </Form.Group>

                <Button className='mt-4' variant="primary" onClick={handleSave}>
                    Save Changes
                </Button>
            </Form>
        </Container>
    );
};

export default Profile;
