import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Card, Button, Modal } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStar } from '@fortawesome/free-solid-svg-icons';
import { faFacebook, faTwitter, faYoutube, faInstagram, faPinterest } from '@fortawesome/free-brands-svg-icons';

function ContactSellerModal({ show, handleClose }) {
  return (
    <Modal show={show} onHide={handleClose} centered>
      <Modal.Header closeButton>
        <Modal.Title className="text-center w-100">Contact Seller</Modal.Title>
      </Modal.Header>
      <Modal.Body className="text-center">
        <h5 className='text-center font-weight-bold'>Download Our Free Pet Adoption and Selling App Now</h5>
        <div className="d-flex justify-content-center my-3">
          <a href="https://play.google.com/store/apps/details?id=com.app.petshome" target="_blank" rel="noopener noreferrer" className="mr-3" title="Download Pets Home App from Google Play Store">
            <img style={{ width: '180px', height: 'auto' }} src="/google-play-badge.svg" alt="Download Pets Home App on Google Play" />
          </a>
          <a href="https://apps.apple.com/app/apple-store/id1549828591?pt=581834&ct=desktop&mt=8" target="_blank" rel="noopener noreferrer" title="Download Pets Home App from Apple App Store">
            <img style={{ width: '180px', height: 'auto' }} src="/app-store-badge.svg" alt="Download Pets Home App on App Store" />
          </a>
        </div>
        <small>Rated 4.7/5 by over 5,547 users</small>
        <div>
          <FontAwesomeIcon icon={faStar} color="gold" />
          <FontAwesomeIcon icon={faStar} color="gold" />
          <FontAwesomeIcon icon={faStar} color="gold" />
          <FontAwesomeIcon icon={faStar} color="gold" />
          <FontAwesomeIcon icon={faStar} color="gold" />
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

function generateAdvertCard(ad, key, handleShowModal) {
  const { title, pet_breed, main_image, author_country_code, asking_price, description } = ad;

  // Flag URL generation based on country code
  const flagUrl = `https://flagcdn.com/24x18/${author_country_code.toLowerCase()}.png`;

  return (
    <Card key={key} style={{ width: '20rem', margin: '16px' }}>
      <div style={{ position: 'relative' }}>
        {main_image && (
          <Card.Img variant="top" src={main_image} style={{ objectFit: 'cover', height: '200px' }} alt={`Image of ${pet_breed}`} />
        )}
        <img
          src={flagUrl}
          alt={`Flag of ${author_country_code}`}
          style={{
            position: 'absolute',
            top: '8px',
            right: '8px',
            width: '24px',
            height: '18px',
            borderRadius: '3px',
            boxShadow: '0 0 5px rgba(0, 0, 0, 0.3)',
          }}
        />
      </div>
      <Card.Body>
        <Card.Title style={{ fontSize: '16px', fontWeight: 'bold' }}>{title}</Card.Title>
        <Card.Text style={{ fontSize: '14px' }}>
          <strong>Breed:</strong> {pet_breed}
        </Card.Text>
        <Card.Text style={{
          fontSize: '12px',
          display: '-webkit-box',
          WebkitLineClamp: 2,
          WebkitBoxOrient: 'vertical',
          overflow: 'hidden'
        }}>
          {description}
        </Card.Text>

        <Card.Text style={{ fontSize: '14px' }}>
          <strong>Rehome Fee:</strong> ${asking_price}
        </Card.Text>
        <Button variant="primary" onClick={handleShowModal}>
          Contact the Seller and Adopt Your Pet Today
        </Button>
      </Card.Body>
    </Card>
  );
}

function generateFaqCard(faq, key) {
  return (
    <Card key={key} className="mb-3">
      <Card.Header as="h5">{faq.title}</Card.Header>
      <Card.Body>
        <Card.Text dangerouslySetInnerHTML={{ __html: faq.excerpt }} />
        <a href={faq.link} className="btn btn-primary" target="_blank" rel="noopener noreferrer">Read More</a>
      </Card.Body>
    </Card>
  );
}

function Home() {
  const [adverts, setAdverts] = useState([]);
  const [faqs, setFaqs] = useState([]);
  const [showModal, setShowModal] = useState(false);

  const handleShowModal = () => setShowModal(true);
  const handleCloseModal = () => setShowModal(false);

  useEffect(() => {
    // Fetch adverts data from the REST API
    fetch('https://us-central1-petshome-da28b.cloudfunctions.net/api/public/pets/all')
      .then(response => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.json();
      })
      .then(data => {
        setAdverts(data);
      })
      .catch(error => {
        console.error('Error fetching adverts data:', error);
      });

    // Fetch FAQs data from the REST API
    fetch('https://blog.petshome.app/wp-json/wp/v2/faqs?_fields[]=title&_fields[]=link&_fields[]=content&_fields[]=featured_media&per_page=100'
    )
      .then(response => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.json();
      })
      .then(data => {

        const faqsWithMedia = data.map(post => {
          const content = post.content?.rendered || "No description available.";
          const truncatedContent = content.length > 250 ? content.substring(0, 250) + "..." : content;

          return {
            title: post.title?.rendered || "Untitled",
            excerpt: truncatedContent,
            link: post.link || "#",
            featuredImageUrl: post.featured_media ? `https://blog.petshome.app/wp-json/wp/v2/media/${post.featured_media}` : null,
          };
        });


        setFaqs(faqsWithMedia);
      })
      .catch(error => {
        console.error('Error fetching FAQs data:', error);
      });
  }, []);

  return (
    <div className="text-dark" style={{ minHeight: '100vh' }}>
      {/* Breadcrumb Navigation */}
      <nav aria-label="breadcrumb">
        <ol className="breadcrumb">
          <li className="breadcrumb-item"><Link to="/">Home</Link></li>
          <li className="breadcrumb-item active" aria-current="page">Sell Your Pet</li>
        </ol>
      </nav>

      <section className="text-center">
        <div className="jumbotron text-dark" style={{ paddingBottom: '0', backgroundColor: 'white' }}>
          <Container>
            <Row>
              <Col md={8} className="p-2">
                <h1 className="display-8 text-dark">
                  Sell Your Dog, Cat, or Pet Online for Free Near You
                </h1>
                <p className="lead">
                  Wondering "<strong>where can I sell my dog online for free</strong>"? Pets Home App simplifies the process for pet owners and breeders to find, sell, or rehome pets both locally and internationally.
                </p>
                <div className="d-flex justify-content-center mt-2 d-none d-sm-block">
                  <Link className="btn bg-primary text-dark btn-lg my-2 mr-4" to="/findpets" aria-label="Find Pets for Sale or Adoption Near You">
                    <i className="fas fa-search"></i> Find Pets for Sale or Adoption Near You
                  </Link>
                  <Link className="btn bg-primary text-dark btn-lg my-2" to="/createadvert" aria-label="Sell Your Pet Online for Free Now">
                    <i className="fas fa-plus"></i> Sell Your Pet Online for Free Now
                  </Link>
                </div>
              </Col>
              <Col md={4} className="p-2">
                <h5 className="text-center font-weight-bold">Download Our Free App Now</h5>
                <div className="d-flex justify-content-center">
                  <a href="https://play.google.com/store/apps/details?id=com.app.petshome" target="_blank" rel="noopener noreferrer" className="mr-3" title="Download Pets Home App from Google Play Store">
                    <img style={{ width: '180px', height: 'auto' }} src="/google-play-badge.svg" alt="Download Pets Home App on Google Play" />
                  </a>
                  <a href="https://apps.apple.com/app/apple-store/id1549828591?pt=581834&ct=desktop&mt=8" target="_blank" rel="noopener noreferrer" title="Download Pets Home App from Apple App Store">
                    <img style={{ width: '180px', height: 'auto' }} src="/app-store-badge.svg" alt="Download Pets Home App on App Store" />
                  </a>
                </div>
                <small>Rated 4.7/5 by over 4,500 users</small>
                <div>
                  <FontAwesomeIcon icon={faStar} color="gold" />
                  <FontAwesomeIcon icon={faStar} color="gold" />
                  <FontAwesomeIcon icon={faStar} color="gold" />
                  <FontAwesomeIcon icon={faStar} color="gold" />
                  <FontAwesomeIcon icon={faStar} color="gold" />
                </div>
              </Col>

              <Col md={6} className="text-center d-block d-md-none">
                <img className="img-fluid rounded mb-4" src="./assets/sell-pets-online-pets-home-app-min.png" alt="Sell Your Pet Online for Free with Pets Home App" />
              </Col>
            </Row>
          </Container>
        </div>
      </section>

      <Container className="p-4">
        <Row>
          <Col md={6} className="text-center d-none d-md-block">
            <img className="img-fluid rounded mb-4" src="./assets/sell-pets-online-pets-home-app-min.png" alt="Sell Your Pet Online for Free with Pets Home App" />
          </Col>
          <Col md={6}>
            <h2>How to Sell Your Pet Online for Free with Pets Home App</h2>
            <hr></hr>
            <p className="lead">
              Looking to <strong>sell your pet fast</strong>? Pets Home App offers free ad postings, real-time chat, and a global platform to connect with potential buyers.
            </p>

            <h3>Breed Scanner</h3>
            <p className="lead">
              Our advanced AI technology allows you to easily detect your dog or cat's breed by uploading an image.
            </p>

            <h3>Pet Social</h3>
            <p className="lead">
              Arrange pet playdates, exchange pet care advice, share breeding tips, and find reliable pet sitting services. Pet Social brings together a community of passionate pet owners ready to help and connect.
            </p>
          </Col>
        </Row>
      </Container>

      <Container className="p-4">
        <Row>
          <Col md={6}>
            <h3>Pet Advisor</h3>
            <p className="lead">
              Introducing Pet Advisor, an AI-powered chatbot designed to assist users with any questions or concerns related to pet adoption and care.
            </p>
          </Col>
          <Col md={6}>
            <h3>Pet Video Library</h3>
            <p className="lead">
              Dive into our comprehensive <a href="https://www.youtube.com/channel/UCTjh3nmz0cVGaX0MnHl6elA/?sub_confirmation=1" target="_blank" rel="noopener noreferrer">video library</a> on the Pets Home App. Our collection spans from basic pet care to detailed guides on breed profiles and advanced health care strategies.
            </p>
          </Col>
        </Row>
      </Container>

      <Container className="p-4">
        <Row>
          <Col md={12} className="p-4">
            <p className="text-center font-weight-bold text-uppercase">Download the app now:</p>
            <div className="d-flex justify-content-center">
              <a href="https://play.google.com/store/apps/details?id=com.app.petshome" target="_blank" rel="noopener noreferrer" className="mr-3" title="Download Pets Home App from Google Play Store">
                <img style={{ width: '180px', height: 'auto' }} src="/google-play-badge.svg" alt="Download Pets Home App on Google Play" />
              </a>
              <a href="https://apps.apple.com/app/apple-store/id1549828591?pt=581834&ct=desktop&mt=8" target="_blank" rel="noopener noreferrer" title="Download Pets Home App from Apple App Store">
                <img style={{ width: '180px', height: 'auto' }} src="/app-store-badge.svg" alt="Download Pets Home App on App Store" />
              </a>
            </div>
            <div className="d-flex justify-content-center">
              <p>Rated 4.9/5 by over 2,890 users: </p>
              <div>
                <FontAwesomeIcon icon={faStar} color="gold" />
                <FontAwesomeIcon icon={faStar} color="gold" />
                <FontAwesomeIcon icon={faStar} color="gold" />
                <FontAwesomeIcon icon={faStar} color="gold" />
                <FontAwesomeIcon icon={faStar} color="gold" />
              </div>
            </div>
            <div className="d-flex justify-content-center mt-4">
              <img style={{ width: '130px', height: 'auto' }} src="/android-adobe-express-qr-code.png" alt="Android QR Code" className="mr-3" />
              <img style={{ width: '130px', height: 'auto' }} src="/ios-express-qr-code.png" alt="iOS QR Code" />
            </div>
            <p className="text-center mt-3 d-none d-md-block">Scan the QR codes below for quick access:</p>
          </Col>
        </Row>
      </Container>

      <Container className="p-4">
        <Row>
          <Col md={12}>
            <h5>Latest Pet Listings</h5>
            <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'center' }}>
              {adverts.map((ad, index) => generateAdvertCard(ad, index, handleShowModal))}
            </div>
          </Col>
        </Row>
      </Container>

      <Container className="p-4">
        <Row>
          <Col md={12}>
            <h5>Frequently Asked Questions (FAQs)</h5>
            {faqs.map((faq, index) => generateFaqCard(faq, index))}
          </Col>
        </Row>
      </Container>

      {/* Social Sharing Buttons */}
      <div className="social-sharing text-center my-4">
        <p>Share this page:</p>
        <a href="https://www.facebook.com/sharer/sharer.php?u=https://www.petshome.app/" target="_blank" rel="noopener noreferrer">
          <FontAwesomeIcon icon={faFacebook} size="2x" className="mx-2" />
        </a>
        <a href="https://twitter.com/intent/tweet?url=https://www.petshome.app/&text=Sell%20Your%20Pet%20Online%20for%20Free%20with%20Pets%20Home%20App" target="_blank" rel="noopener noreferrer">
          <FontAwesomeIcon icon={faTwitter} size="2x" className="mx-2" />
        </a>
        <a href="https://www.instagram.com/petshomeapp/" target="_blank" rel="noopener noreferrer">
          <FontAwesomeIcon icon={faInstagram} size="2x" className="mx-2" />
        </a>
        <a href="https://www.pinterest.com/petshomeapp/" target="_blank" rel="noopener noreferrer">
          <FontAwesomeIcon icon={faPinterest} size="2x" className="mx-2" />
        </a>
        <a href="https://www.youtube.com/channel/UCTjh3nmz0cVGaX0MnHl6elA" target="_blank" rel="noopener noreferrer">
          <FontAwesomeIcon icon={faYoutube} size="2x" className="mx-2" />
        </a>
      </div>

      {/* Final Call to Action */}
      <div className="text-center my-4">
        <h2>Ready to Sell Your Pet Online for Free?</h2>
        <Link className="btn bg-primary text-dark btn-lg my-2" to="/createadvert" aria-label="Post Your Free Pet Ad Now">
          <i className="fas fa-plus"></i> Post Your Free Pet Ad Now
        </Link>
      </div>

      {/* Contact Seller Modal */}
      <ContactSellerModal show={showModal} handleClose={handleCloseModal} />
    </div>
  );
}

export default Home;
