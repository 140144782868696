


import React, { useState, useEffect, useContext } from 'react';
import { UserAuth } from '../context/AuthContext';
import { Container, Row, Col, Card, Button, Carousel, CardFooter, Navbar, Nav, Form, ButtonGroup, Alert, Modal } from 'react-bootstrap';
import { pet_types } from '../data/pet_types'; // Adjust the path to match your file location
import CountrySelector from '../components/CountrySelector';


import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStar } from '@fortawesome/free-solid-svg-icons';
import { faFacebook, faTwitter, faYoutube, faInstagram, faPinterest } from '@fortawesome/free-brands-svg-icons';



import {
    getFirestore, collection, query, where, onSnapshot, orderBy, getDocs,
    doc, setDoc, updateDoc, addDoc, limit, deleteDoc
} from 'firebase/firestore';
import { Link, useLocation } from 'react-router-dom';
import { storage, db } from '../firebase'; // Import Firebase configurations
import ChatPanel from '../components/ChatPanel';



function ContactSellerModal({ show, handleClose }) {
    return (
        <Modal show={show} onHide={handleClose} centered>
            <Modal.Header closeButton>
                <Modal.Title className="text-center w-100">Contact Seller</Modal.Title>
            </Modal.Header>
            <Modal.Body className="text-center">
                <h5 className='text-center font-weight-bold'>Download Our Free Pet Adoption and Selling App Now</h5>
                <div className="d-flex justify-content-center my-3">
                    <a href="https://play.google.com/store/apps/details?id=com.app.petshome" target="_blank" rel="noopener noreferrer" className="mr-3">
                        <img style={{ width: '180px', height: 'auto' }} src="/google-play-badge.svg" alt="Google Play Store Download" />
                    </a>
                    <a href="https://apps.apple.com/app/apple-store/id1549828591?pt=581834&ct=desktop&mt=8" target="_blank" rel="noopener noreferrer">
                        <img style={{ width: '180px', height: 'auto' }} src="/app-store-badge.svg" alt="App Store Download" />
                    </a>
                </div>
                <small>Rated 4.7/5 by over 3,347 users</small>
                <div>
                    <FontAwesomeIcon icon={faStar} color="gold" />
                    <FontAwesomeIcon icon={faStar} color="gold" />
                    <FontAwesomeIcon icon={faStar} color="gold" />
                    <FontAwesomeIcon icon={faStar} color="gold" />
                    <FontAwesomeIcon icon={faStar} color="gold" />
                </div>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                    Close
                </Button>
            </Modal.Footer>
        </Modal>
    );
}

function generateAdvertCard(ad, key, handleShowModal) {
    const { title, pet_breed, main_image, author_country_code, asking_price, description } = ad;

    // Flag URL generation based on country code
    const flagUrl = `https://flagcdn.com/24x18/${author_country_code.toLowerCase()}.png`;

    return (
        <Card key={key} style={{ width: '20rem', margin: '16px' }}>
            <div style={{ position: 'relative' }}>
                {main_image && (
                    <Card.Img variant="top" src={main_image} style={{ objectFit: 'cover', height: '200px' }} alt={`Image of ${pet_breed}`} />
                )}
                <img
                    src={flagUrl}
                    alt={`Flag of ${author_country_code}`}
                    style={{
                        position: 'absolute',
                        top: '8px',
                        right: '8px',
                        width: '24px',
                        height: '18px',
                        borderRadius: '3px',
                        boxShadow: '0 0 5px rgba(0, 0, 0, 0.3)',
                    }}
                />
            </div>
            <Card.Body>
                <Card.Title style={{ fontSize: '16px', fontWeight: 'bold' }}>{title}</Card.Title>
                <Card.Text style={{ fontSize: '14px' }}>
                    <strong>Breed:</strong> {pet_breed}
                </Card.Text>
                <Card.Text style={{
                    fontSize: '12px',
                    display: '-webkit-box',
                    WebkitLineClamp: 2,
                    WebkitBoxOrient: 'vertical',
                    overflow: 'hidden'
                }}>
                    {description}
                </Card.Text>

                <Card.Text style={{ fontSize: '14px' }}>
                    <strong>Rehome Fee:</strong> ${asking_price}
                </Card.Text>
                <Button variant="primary" onClick={handleShowModal}>
                    Contact the Seller and Adopt Your Pet Today
                </Button>
            </Card.Body>
        </Card>
    );
}

function getTimeSincePublication(utcTimestamp) {
    const currentDate = new Date();
    const publishDate = new Date(utcTimestamp);

    const timeDifference = currentDate - publishDate;
    const seconds = Math.floor(timeDifference / 1000);
    const minutes = Math.floor(seconds / 60);
    const hours = Math.floor(minutes / 60);
    const days = Math.floor(hours / 24);

    if (days > 0) {
        return `${days} ${days === 1 ? 'day' : 'days'} ago`;
    } else if (hours > 0) {
        return `${hours} ${hours === 1 ? 'hour' : 'hours'} ago`;
    } else if (minutes > 0) {
        return `${minutes} ${minutes === 1 ? 'minute' : 'minutes'} ago`;
    } else {
        return `${seconds} ${seconds === 1 ? 'second' : 'seconds'} ago`;
    }
}


const FindPets = () => {

    const [showModal, setShowModal] = useState(false);

    const handleShowModal = () => setShowModal(true);
    const handleCloseModal = () => setShowModal(false);
    const [adverts, setAdverts] = useState([]);
    useEffect(() => {
        // Fetch adverts data from the REST API
        fetch('https://us-central1-petshome-da28b.cloudfunctions.net/api/public/pets/all')
            .then(response => {
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                return response.json();
            })
            .then(data => {
                setAdverts(data);
            })
            .catch(error => {
                console.error('Error fetching adverts data:', error);
            });
    });

    const { user, userData } = UserAuth();
    const [pets, setPets] = useState([]);
    const [loading, setLoading] = useState(true);
    const [selectedCountry, setSelectedCountry] = useState(
        userData.country_code || 'US' // Use userData.country_code if available, otherwise use 'US'
    );
    const [isChatPanelOpen, setIsChatPanelOpen] = useState(false);
    const [selectedImageIndex, setSelectedImageIndex] = useState(0);
    const [errorMessage, setErrorMessage] = useState('');
    const [queryData, setQueryData] = useState({

        pet_type: '',
        pet_breed: '',
        country: selectedCountry

    });
    useEffect(() => {
        if (userData && userData.country_code) {
            setSelectedCountry(userData.country_code);
        }
    }, [userData]); // Depend on userData






    const [searchRadius, setSearchRadius] = useState('500'); // Default search radius is 10 miles
    const [localSearchEnabled, setLocalSearchEnabled] = useState(false);
    const [showLocationAlert, setShowLocationAlert] = useState(false);

    const handleSwitchChange = () => {
        if (!userData.location || Object.keys(userData.location).length === 0) {
            // Location is not available, show the location alert
            setShowLocationAlert(true);

        } else {
            setLocalSearchEnabled(!localSearchEnabled);

        }
    };

    const handleRadiusChange = (value) => {
        setSearchRadius(value);
    };


    const [showSendMessagePanel, setShowSendMessagePanel] = useState(false);
    const [selectedPet, setSelectedPet] = useState(null); // Store the selected pet data here

    // Function to handle when the "Send message" button is clicked
    const handleSendMessageClick = (pet) => {
        setSelectedPet(pet);
        setShowSendMessagePanel(true);
    };

    // Function to close the SendMessagePanel
    const handleCloseSendMessagePanel = () => {
        setSelectedPet(null);
        setShowSendMessagePanel(false);
    };

    const enableLocation = () => {
        // Add logic to enable location here
        // You can use the browser's geolocation API to request location permissions
        navigator.geolocation.getCurrentPosition(
            (position) => {
                // Handle successful location permission here
                const { latitude, longitude } = position.coords;
                updateUserLocation(latitude, longitude);
                setShowLocationAlert(false); // Hide the location alert after enabling location
            },
            (error) => {
                // Handle location permission denied or other errors
                if (error.code === 1) {
                    console.log('Location permission denied. Provide guidance here.');
                    setShowLocationAlert(false); // Hide the location alert if permission is denied
                } else {
                    console.error('Error getting location:', error.message);
                    setShowLocationAlert(false); // Hide the location alert on other errors
                }
            }
        );
    };

    const [petTypes, setPetTypes] = useState([]);
    const [breedTypes, setBreedTypes] = useState([]);


    useEffect(() => {
        // Set the pet types data from the imported JavaScript file
        setPetTypes(pet_types.map((type) => type.name));
    }, []);


    useEffect(() => {
        if (!userData.location || Object.keys(userData.location).length === 0) {
            // Check if the cached location is available
            if (navigator.geolocation && navigator.geolocation.lastPosition) {
                const { latitude, longitude } = navigator.geolocation.lastPosition.coords;
                // Use the cached latitude and longitude here
                updateUserLocation(latitude, longitude);
            } else {
                // If there's no cached location, request the current location
                navigator.geolocation.getCurrentPosition(
                    (position) => {
                        // Handle successful location permission here
                        const { latitude, longitude } = position.coords;
                        updateUserLocation(latitude, longitude);
                        // setErrorMessage('');
                    },
                    (error) => {
                        // Handle location permission denied or other errors
                        if (error.code === 1) {
                            console.log('Location permission denied. Provide guidance here.');
                            // setErrorMessage('Location permission denied.');
                            // setLocationPermissionRequested(true);
                        } else {
                            console.error('Error getting location:', error.message);
                            // setErrorMessage('Error getting location. Please try again later.');
                        }
                    }
                );
            }
        }
    }, [user, userData.location]);

    const updateUserLocation = async () => {

        if (!user) {
            return;
        }

        if (!navigator.geolocation) {

            setErrorMessage('Geolocation is not supported by your browser');
            return;
        }

        // Request location permission from the user
        navigator.geolocation.getCurrentPosition(
            async (position) => {
                const { latitude, longitude } = position.coords;

                // Update the user's location in Firestore

                // Assuming you have the user's UID
                const userDocRef = doc(db, 'users', user.uid);

                try {
                    await updateDoc(userDocRef, {
                        location: {
                            coordinates: [latitude, longitude],
                            type: 'Point',
                        },
                    });
                    console.log('User location updated successfully');
                    // setLocationSavedSuccessfully(true);

                } catch (error) {
                    // console.error('Error updating user location: ', error);
                    setErrorMessage('Error updating user location');
                }
            },
            (error) => {
                console.error('Error getting user location: ', error);
                // setErrorMessage('Error getting user location');
            }
        );
    };


    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setQueryData({ ...queryData, [name]: value });

        console.log(queryData)

    };




    const handleSelect = (index, petId) => {
        setSelectedImageIndex({ ...selectedImageIndex, [petId]: index });
    };

    useEffect(() => {
        // Fetch pets based on queryData when selectedCountry changes or on initial load
        async function fetchPets() {
            try {
                setLoading(true);
                // Create the payload based on queryData

                let fetchURL = 'https://us-central1-petshome-da28b.cloudfunctions.net/api/private/pets/worldissauthenticated';


                let whereQuery = '';

                if (queryData.pet_type) {
                    whereQuery += ` and c.pet_type = "${queryData.pet_type}"`;
                }
                if (queryData.pet_breed && queryData.pet_breed !== 'Any') {
                    whereQuery += ` and c.pet_breed = "${queryData.pet_breed}"`;
                }

                let payload = {
                    country: selectedCountry,
                    whereQuery: whereQuery,
                };


                if (localSearchEnabled === true && userData.location && Object.keys(userData.location).length != 0) {
                    fetchURL = 'https://us-central1-petshome-da28b.cloudfunctions.net/api/private/pets/localssauthenticated';
                    payload = {
                        location: userData.location,
                        distance: parseInt(searchRadius) * 1000,
                        country: userData.country_code,
                        whereQuery: whereQuery,
                    };
                }
                // Build the query based on queryData


                // console.log(localSearchEnabled, payload, fetchURL);


                // Get the user's token
                const token = await user.getIdToken();

                // Make the API request
                const headers = {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`,
                };

                const response = await fetch(fetchURL,
                    {
                        method: 'POST',
                        headers: headers,
                        body: JSON.stringify(payload),
                    }
                );

                if (!response.ok) {
                    throw new Error(`Network response was not ok (Status: ${response.status})`);
                }

                const data = await response.json();
                setPets(data);

                // console.log(data)

                // Simulate a 2-second delay before setting loading to false
                setTimeout(() => {
                    setLoading(false);
                }, 2000);
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        }

        fetchPets();
    }, [user, queryData.country, queryData.pet_type, queryData.pet_breed, selectedCountry, localSearchEnabled, searchRadius]);


    return (
        <Container>

            {user?.uid ? (
                <>
                    {loading && ( // Render the loading spinner when loading is true
                        <div className="loading-spinner">
                            <div className="spinner-border text-primary" role="status">
                                <span className="visually-hidden">Loading...</span>
                            </div>
                        </div>
                    )}



                    {showSendMessagePanel && (
                        <ChatPanel
                            selectedPet={selectedPet}
                            onClose={handleCloseSendMessagePanel}
                        />
                    )}


                    <h2 className="my-4">Find Your Perfect Pet</h2>
                    <p className="text-muted">Discover a variety of adorable pets waiting for their forever homes. Browse through our listings and find your new furry friend today!</p>
                    <hr />
                    <Navbar
                        expand="lg">
                        <Navbar.Toggle aria-controls="basic-navbar-nav" />
                        <Navbar.Collapse id="basic-navbar-nav">
                            <Nav className="mr-auto">

                                <Form.Group controlId="countrySelect" className="mr-4">
                                    <Form.Label>Country</Form.Label>
                                    <CountrySelector selectedCountry={selectedCountry} setSelectedCountry={setSelectedCountry} style={{ width: "220px" }} />
                                </Form.Group>

                                <Form.Group controlId="petType" className='mr-4'>
                                    <Form.Label>Pet Type</Form.Label>
                                    <Form.Control
                                        as="select"
                                        name="pet_type"
                                        value={queryData.pet_type}
                                        onChange={handleInputChange}
                                        required
                                    >
                                        <option value="">Select Pet Type</option>
                                        {pet_types.map((type) => (
                                            <option key={type.name} value={type.name}>
                                                {type.name}
                                            </option>
                                        ))}
                                    </Form.Control>
                                </Form.Group>
                                {/* Select dropdown for petBreed */}
                                <Form.Group controlId="pet_breed" className='mr-4'>
                                    <Form.Label>Pet Breed</Form.Label>
                                    <Form.Control
                                        as="select"
                                        name="pet_breed"
                                        value={queryData.pet_breed}
                                        onChange={handleInputChange}
                                        required
                                    >
                                        <option value="">Select Pet Breed</option>
                                        {queryData.pet_type &&
                                            (pet_types
                                                .find((type) => type.name === queryData.pet_type)
                                                ?.breeds || ['All']) // Use ['All'] if breeds are not available
                                                .map((breed) => (
                                                    <option key={breed} value={breed}>
                                                        {breed}
                                                    </option>
                                                ))}
                                    </Form.Control>
                                </Form.Group>




                            </Nav>
                            {userData.location && Object.keys(userData.location).length > 0 && (
                                <Form inline>
                                    <Form.Check
                                        className='ml-2'
                                        type="switch"
                                        id="localSearchSwitch"
                                        label="Local Search"
                                        checked={localSearchEnabled}
                                        onChange={handleSwitchChange}
                                    />
                                    {localSearchEnabled && (
                                        <ButtonGroup className="ml-2">
                                            <Button
                                                className='btn-sm'
                                                variant={searchRadius === '10' ? 'primary' : 'outline-primary'}
                                                onClick={() => handleRadiusChange('10')}
                                            >
                                                10 miles
                                            </Button>
                                            <Button
                                                className='btn-sm'
                                                variant={searchRadius === '100' ? 'primary' : 'outline-primary'}
                                                onClick={() => handleRadiusChange('100')}
                                            >
                                                100 miles
                                            </Button>
                                            <Button
                                                className='btn-sm'
                                                variant={searchRadius === '500' ? 'primary' : 'outline-primary'}
                                                onClick={() => handleRadiusChange('500')}
                                            >
                                                500 miles
                                            </Button>
                                        </ButtonGroup>
                                    )}
                                </Form>
                            )}
                        </Navbar.Collapse>


                    </Navbar>
                    {showLocationAlert && (
                        <Alert variant="warning" className="m-2">
                            To enable local search, please allow location access.{' '}
                            <Button variant="warning" size="sm" onClick={enableLocation}>
                                Enable Location
                            </Button>
                        </Alert>
                    )}
                    <Row className='mt-4'>
                        {pets.map((pet) => (
                            <Col key={pet.id} md={3} sm={6} xs={12} className="mb-4">
                                <Card>
                                    <div
                                        style={{
                                            overflow: 'hidden',
                                            maxHeight: '200px',
                                            position: 'relative',
                                        }}
                                    >
                                        {pet.images['main_image'] && (
                                            <Carousel
                                                activeIndex={selectedImageIndex[pet.id] || 0}
                                                onSelect={(index) => handleSelect(index, pet.id)}
                                                interval={null}
                                            >
                                                {Object.entries(pet.images).map(([key, image], index) => (
                                                    <Carousel.Item key={index}>
                                                        <img
                                                            src={image}
                                                            alt={pet.name}
                                                            style={{
                                                                objectFit: 'cover',
                                                                width: '100%',
                                                                height: '200px',
                                                            }}
                                                        />
                                                    </Carousel.Item>
                                                ))}
                                            </Carousel>
                                        )}

                                        <div
                                            style={{ position: 'absolute', top: '10px', right: '10px' }}
                                        >
                                            <span className={`fi fi-${pet.country.toLowerCase()} fib`}></span>
                                        </div>

                                        {pet.publish_date && pet.publish_date > 0 && (
                                            <div
                                                style={{ position: 'absolute', top: '10px', left: '10px' }}
                                            >
                                                <small className='text-light'>  {getTimeSincePublication(pet.publish_date)}  </small>
                                            </div>
                                        )}

                                        {pet.page_view && pet.page_view > 0 && (
                                            <div
                                                style={{ position: 'absolute', bottom: '0px', left: '10px' }}
                                            >
                                                <small className='text-light'> <i class="fa fa-eye" aria-hidden="true"></i> {pet.page_view} </small>
                                            </div>
                                        )}

                                        {pet.report_like && pet.report_like > 0 && (
                                            <div
                                                style={{ position: 'absolute', bottom: '0px', right: '10px' }}
                                            >
                                                <small className='text-light'> <i class="fa fa-heart" aria-hidden="true"></i> {pet.report_like} </small>
                                            </div>
                                        )}


                                        {pet.distance && pet.distance > 0 && (
                                            <div
                                                style={{ position: 'absolute', top: '30px', left: '10px' }}
                                            >
                                                <small className='text-light'> <i class="fa fa-map-marker" aria-hidden="true"></i> {pet.distance} miles</small>
                                            </div>
                                        )}





                                    </div>


                                    <Card.Body style={{ height: '200px' }}>
                                        <Card.Title style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', maxWidth: '100%', lineHeight: '1.5em', maxHeight: '3em' }}>
                                            <small>{pet.title}</small>
                                        </Card.Title>


                                        <ul className="list-group list-group-flush">
                                            {pet.pet_breed && (
                                                <li className="list-group-item p-0">
                                                    <small>Breed: {pet.pet_breed}</small>
                                                </li>
                                            )}
                                            {pet.asking_price && (
                                                <li className="list-group-item p-0">
                                                    <small>Rehome Fee: {pet.asking_price}</small>
                                                </li>
                                            )}

                                            {pet.description && (
                                                <li className="list-group-item p-0">
                                                    <div style={{ overflow: 'auto', maxHeight: '5em' }}>
                                                        <small>
                                                            {pet.description}
                                                        </small>
                                                    </div>

                                                </li>
                                            )}
                                        </ul>

                                    </Card.Body>
                                    <CardFooter >
                                        <div className='d-flex justify-content-center'>
                                            {user?.uid !== pet.author_Id && (
                                                <Button
                                                    className="btn btn-sm"
                                                    variant="success"
                                                    onClick={() => handleSendMessageClick(pet)}
                                                >
                                                    Send message
                                                </Button>
                                            )}

                                        </div>
                                    </CardFooter>
                                </Card>
                            </Col>
                        ))}
                    </Row>




                </>
            ) :

                <>


                    <Row>


                        <Col md={12} className=' p-4'>
                            <h2 className="my-4">Find Your Perfect Pet </h2>
                            <p className="text-muted">Discover a variety of adorable pets waiting for their forever homes. Browse through our listings and find your new furry friend today!</p>
                            <hr />
                        </Col>

                        <Col md={12} className=' p-4'>

                            <p className="">Experience a more convenient way to connect with potential adopters. Our mobile app offers features like instant notifications when someone contacts you, easy photo uploads, and the ability to share videos of your pet's charm.</p>
                            <p className='text-center font-weight-bold text-uppercase'>Download Our Free App Now</p>
                            <div className="d-flex justify-content-center">
                                <a style={{ width: '130px', height: 'auto' }} href="https://play.google.com/store/apps/details?id=com.app.petshome" target="_blank" rel="noopener noreferrer" className="mr-3"><img src="/google-play-badge.svg" alt="Google Play" /></a>
                                <a style={{ width: '130px', height: 'auto' }} href="https://apps.apple.com/us/app/pets-home-app/id1549828591?pt=581834&ct=desktop&mt=8" target="_blank" rel="noopener noreferrer"><img src="/app-store-badge.svg" alt="App Store" /></a>
                            </div>

                            <div className="d-flex justify-content-center">
                                <img style={{ width: '130px', height: 'auto' }} src="/android-adobe-express-qr-code.png" alt="Android QR Code" className="mr-3" />
                                <img style={{ width: '130px', height: 'auto' }} src="/ios-express-qr-code.png" alt="iOS QR Code" />
                            </div>
                            <p className="text-center mt-3">Scan the QR codes below for quick access:</p>


                        </Col>

                        <Container className="p-4">
                            <Row>
                                <Col md={12}>
                                    <h5>Latest Pet Listings</h5>
                                    <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'center' }}>
                                        {adverts.map((ad, index) => generateAdvertCard(ad, index, handleShowModal))}
                                    </div>
                                </Col>
                            </Row>
                        </Container>

                        {/* <Col md={6} className=' p-4'>
                            <div className="text-center mt-4 ">
                                <p>
                                    <Link
                                        type="button"
                                        className="btn btn-outline-success my-2 my-sm-0 btn-sm"
                                        to='/signin'
                                    >
                                        Sign in / Sign up
                                    </Link>
                                </p>

                                <p>At Pets Home App, we prioritize creating a community dedicated to responsible pet ownership and fostering genuine connections between pet lovers, rehomers, and breeders. To ensure the exclusivity of our platform and maintain a high standard of engagement, we require all users to undergo authentication. By authenticating users, we streamline the process, filtering out time-wasters and ensuring that our platform remains a space for serious buyers, rehomers, and breeders to connect efficiently and meaningfully. This authentication process not only enhances the quality of interactions but also reinforces our commitment to promoting responsible pet care practices throughout our community.</p>




                            </div>
                        </Col> */}

                        {/* <Col md={6} className=' p-4'>

                            <p className="">Experience a more convenient way to connect with potential adopters. Our mobile app offers features like instant notifications when someone contacts you, easy photo uploads, and the ability to share videos of your pet's charm.</p>
                            <p className='text-center font-weight-bold text-uppercase'>Download the app now:</p>
                            <div className="d-flex justify-content-center">
                                <a style={{ width: '130px', height: 'auto' }} href="https://play.google.com/store/apps/details?id=com.app.petshome" target="_blank" rel="noopener noreferrer" className="mr-3"><img src="/google-play-badge.svg" alt="Google Play" /></a>
                                <a style={{ width: '130px', height: 'auto' }} href="https://apps.apple.com/us/app/pets-home-app/id1549828591?pt=581834&ct=desktop&mt=8" target="_blank" rel="noopener noreferrer"><img src="/app-store-badge.svg" alt="App Store" /></a>
                            </div>

                            <div className="d-flex justify-content-center">
                                <img style={{ width: '130px', height: 'auto' }} src="/android-adobe-express-qr-code.png" alt="Android QR Code" className="mr-3" />
                                <img style={{ width: '130px', height: 'auto' }} src="/ios-express-qr-code.png" alt="iOS QR Code" />
                            </div>
                            <p className="text-center mt-3">Scan the QR codes below for quick access:</p>


                        </Col> */}
                    </Row>
                    <div className="text-center mt-4 ">

                    </div>

                    {/* Contact Seller Modal */}
                    <ContactSellerModal show={showModal} handleClose={handleCloseModal} />
                </>
            }


        </Container>



    );
};

export default FindPets;
