import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';
import { getFirestore } from 'firebase/firestore';
import { getStorage } from 'firebase/storage'; // Import Firebase Storage

// Your Firebase config object, which you'd get from your Firebase project settings.
const firebaseConfig = {
    apiKey: "AIzaSyAjncPDn6-d6EqFRjqe4Lppyz4Zl_252tY",
    authDomain: "petshome-da28b.firebaseapp.com",
    projectId: "petshome-da28b",
    storageBucket: "petshome-da28b.appspot.com",
    messagingSenderId: "460609152076",
    appId: "1:460609152076:web:5fdbd6f80e50f1c8e400e9",
    measurementId: "G-KNML31PYR5"
};

const app = initializeApp(firebaseConfig);
const db = getFirestore(app);
const auth = getAuth(app);
const storage = getStorage(app); // Initialize Firebase Storage

export { auth, app, db, firebaseConfig, storage };


