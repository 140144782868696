import React, { useState, useEffect, useContext, useRef } from 'react';
import { Container, Form, Button, Col, Row, Alert, Spinner } from 'react-bootstrap';
import { Link, useLocation } from 'react-router-dom';
import {
    getFirestore, collection, query, where, onSnapshot, orderBy, getDocs,
    doc, setDoc, updateDoc, addDoc, limit, deleteDoc
} from 'firebase/firestore';
import { format } from 'date-fns'
import { storage, db } from '../firebase'; // Import Firebase configurations
import { pet_types } from '../data/pet_types'; // Adjust the path to match your file location
import { UserAuth, userData } from '../context/AuthContext'; // Import your authentication context
import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage"
import Filter from 'bad-words'; // A profanity filter library
const filter = new Filter();


const ChatPanel = ({ selectedPet, onClose }) => {
    const { user, userData } = UserAuth();
    const [errorMessage, setErrorMessage] = useState('');
    const messageInputRef = useRef(null);
    const [messages, setMessages] = useState([]);
    const messagesEndRef = useRef(null); // Reference to auto-scroll the messages container
    const [sendingMessage, setSendingMessage] = useState(false);
    // console.log("user", user)
    // console.log("userData", userData)
    // console.log("selectedPet", selectedPet)

    const [createCollection, setCreateCollection] = useState(true);

    let chatChannelDocId = selectedPet.chatChannelDocId || (selectedPet.id + selectedPet.author_Id + user.uid);






    // Check if the chatChannelDocId exists in Firestore when the component mounts
    useEffect(() => {
        const checkChatChannelExistence = async () => {
            const firestore = getFirestore();
            const messagesCollectionRef = collection(firestore, 'messages');
            const queryRef = query(
                messagesCollectionRef,
                where('channelId', '==', chatChannelDocId)
            );

            try {
                const querySnapshot = await getDocs(queryRef);

                if (querySnapshot.size === 0) {
                    // Document doesn't exist, set createCollection to true
                    console.log('Document doesnt exist');
                    setCreateCollection(true)

                } else {
                    // Document exists, set createCollection to false
                    console.log('Document alreayd exist');
                    setCreateCollection(false)
                }
            } catch (error) {
                console.error('Error checking document:', error);
            }
        };

        checkChatChannelExistence();
    }, [chatChannelDocId]);


    useEffect(() => {
        if (chatChannelDocId && user) {
            const messagesRef = collection(db, "messages", chatChannelDocId, "chat");
            const q = query(messagesRef, orderBy("utc", "asc"));

            const unsubscribe = onSnapshot(q, (snapshot) => {
                let fetchedMessages = [];
                snapshot.forEach(doc => {
                    fetchedMessages.push(doc.data());
                });
                setMessages(fetchedMessages);

            });

            return () => unsubscribe(); // Cleanup listener on component unmount
        }
    }, [chatChannelDocId, user]);

    useEffect(() => {
        // Get the messages container div
        const messagesContainer = document.querySelector('.messages-list');
        if (messagesContainer) {
            // Scroll to the bottom
            messagesContainer.scrollTop = messagesContainer.scrollHeight;
        }
    }, [messages.length]); // Depend on the length of the messages array


    const isValidTextInput = (text, lenght) => {
        // Remove leading and trailing whitespaces
        text = text.trim();

        // Use a regex pattern to split the string into words
        const words = text.split(/\s+/);

        // Check if there are at least three words
        return words.length >= lenght;
    };


    const handleInputChange = (e) => {
        const { name, value } = e.target;

        // Define character limits for the fields
        const maxMessageLength = 200;
        const validTextPattern = /^[a-zA-Z.,'"\s-]+$/;

        // Check for profanity

        if (name === "message") {
            if (value.length > maxMessageLength) {
                // Display an error message for the field if it exceeds the limit
                setErrorMessage(`${name} must be no more than ${maxMessageLength} characters.`);
            } else if (!validTextPattern.test(value) && value !== "") {
                // Display an error message if the input contains invalid characters
                setErrorMessage(`${name} contains invalid characters.`);
            } else {
                // Clear the error message and update the state with the valid input
                setErrorMessage("");

            }

        }
    };


    const handleSubmitMessage = async () => {
        setSendingMessage(true)
        let message = messageInputRef.current.value;
        message = message.trim();


        if (!user) {
            setErrorMessage('Session expired');
            return;
        }

        if (message.length > 200) {
            // Display an error message for the field if it exceeds the limit
            setErrorMessage(`Message must be no more than 200 characters.`);
        }
        const isMessageClean = !filter.isProfane(message);
        if (!isMessageClean) {
            setErrorMessage('Please avoid using profanity.');
            return false;
        }

        if (!isValidTextInput(message, 3)) {
            // Display an error message for the title field
            setErrorMessage('Message must contain at least 3 words.');
            return false;
        }

        if (message !== '') {

            const currentDate = new Date();
            const date = format(currentDate, 'dd/MM/yyyy hh:mm:ss');

            if (createCollection) {
                try {

                    const chatChannelData = {
                        "users": [
                            selectedPet.author_Id,
                            user.uid,
                        ],
                        "createDate": date,
                        "image": selectedPet.images.main_image,
                        "title": selectedPet.title,
                        "status": true,
                        "advert_Id": selectedPet.id,
                        "advert_owner_Id": selectedPet.author_Id,
                        "advert_owner_name": selectedPet.name,
                        "chat_owner_id": user.uid,
                        "chat_owner_name": userData.name,
                        "report_status": null,
                        "deleted_by": null,
                        "channelManagementStatus": null,

                    };
                    console.log("chatChannelData:", chatChannelData)
                    // Add the chat channel document



                    await setDoc(doc(db, 'messages', chatChannelDocId), chatChannelData);
                    console.log('created chat channel')
                    setCreateCollection(false); // Set createCollection to false after creating the chat channel
                } catch (error) {
                    console.error('Error creating chat channel:', error);
                }
            }

            try {
                // Create a new message document
                const messageData = {
                    "senderId": user.uid,
                    "time": date,
                    "text": message,
                    "unread": true,
                    "utc": Date.now(),
                    // Add other message data as needed
                };



                const messagesRef = collection(db, "messages", chatChannelDocId, "chat");
                await addDoc(messagesRef, messageData);

                console.log("message saved")

                // Clear the message input field or take other necessary actions
                messageInputRef.current.value = '';
                setSendingMessage(false)

            } catch (error) {
                console.error('Error sending message:', error);
            }
        }
    };

    // useEffect(() => {
    //     if (activeChatDocType && activeChatDoc) {
    //         const messagesRef = collection(db, "messages", activeChatDoc.chatId, "chat");
    //         const q = query(messagesRef, orderBy("utcTimestamp", "asc"), limit(100));

    //         const unsubscribe = onSnapshot(q, (snapshot) => {
    //             let fetchedMessages = [];
    //             snapshot.forEach(doc => {
    //                 fetchedMessages.push(doc.data());
    //             });
    //             setMessages(fetchedMessages);
    //         });

    //         return () => unsubscribe(); // Cleanup listener on component unmount
    //     }
    // }, [user]);

    const closeChatPanel = () => {
        setMessages([]);
        setCreateCollection(true)
        onClose();
    };

    return (
        <div
            className="position-fixed h-100 d-flex flex-column "
            style={{
                top: 0,
                right: 0,
                width: '30%',
                backgroundColor: '#f8f9fa',
                zIndex: 99999,
                overflowY: 'auto',
                boxShadow: '-2px 0 5px rgba(0, 0, 0, 0.3)'
            }}
        >

            {/* Top: Document Details */}
            <div className="p-3 bg-light border-bottom">
                <h5 className="">
                    {selectedPet.title}
                </h5>

                {/* Other document details here */}

                <Alert variant="warning" className="m-2">
                    <small className="text-muted">
                        Be cautious! It's your responsibility to verify information and deal with caution when engaging with unknown parties. Always prioritize your safety and exercise due diligence.
                    </small>
                </Alert>



            </div>

            {/* Middle: Chat Messages */}
            <div className="messages-list flex-grow-1 p-3 overflow-auto">
                {messages.map((message, index) => (
                    <div
                        style={{ maxWidth: '70%', clear: 'both' }}
                        key={index}
                        className={`mb-3 ${message.senderId === user.uid ? 'float-left' : 'float-right'
                            }`}
                    >
                        <div
                            className={`rounded p-3 ${message.senderId !== user.uid ? 'bg-secondary text-white' : 'bg-info text-white'
                                }`}
                        // Limit message width
                        >
                            <p className="mb-0">{message.text}</p>
                            <small style={{ fontSize: 11 }}>
                                {new Date(message.utc).toLocaleString(undefined, {
                                    year: 'numeric',
                                    month: 'short',
                                    day: 'numeric',
                                    hour: 'numeric',
                                    minute: 'numeric',
                                })}
                            </small>
                        </div>
                    </div>
                ))}
                <div ref={messagesEndRef} />
            </div>




            {/* Bottom: Input Area */}
            <div className="p-3 bg-light border-top">

                {errorMessage && (
                    <Alert className='mt-4' variant="warning" onClose={() => setErrorMessage('')} dismissible>
                        {errorMessage}
                    </Alert>
                )}

                <textarea
                    ref={messageInputRef}
                    name="message"
                    className="form-control mb-2"
                    placeholder="Type your message..."
                    onChange={handleInputChange}
                    maxLength={300}
                    pattern="[^!@#$%^&*()_+=]+"
                />

                <div className="d-flex justify-content-between">


                    <div className="d-flex justify-content-between" style={{ width: '100%' }}>
                        <button
                            className="btn btn-light btn-xs"
                            onClick={closeChatPanel}
                        >
                            Close
                        </button>

                        {/* <button className="btn btn-success" onClick={handleSubmitMessage} disabled={sendingMessage}>
                            {'Send'}
                        </button> */}

                        <Button
                            className="btn btn-success"
                            onClick={handleSubmitMessage}
                            disabled={sendingMessage}
                        >
                            {sendingMessage ? (
                                <>
                                    <Spinner
                                        as="span"
                                        animation="border"
                                        size="sm"
                                        role="status"
                                        aria-hidden="true"
                                    />
                                    Sending...
                                </>
                            ) : (
                                'Send'
                            )}
                        </Button>

                    </div>
                </div>
            </div>

            <button
                className="btn btn-light btn-xs m-2 float-right"
                style={{ position: 'absolute', top: '10px', right: '10px' }}
                onClick={closeChatPanel}
            >
                Close
            </button>

        </div>
    );
};

export default ChatPanel;
