import React, { useMemo } from 'react';
import Select from 'react-select';
import countryList from 'react-select-country-list';

function CountrySelector({ selectedCountry, setSelectedCountry }) {
    const options = useMemo(() => countryList().getData(), []);

    const handleChange = (selectedOption) => {
        setSelectedCountry(selectedOption ? selectedOption.value : '');
    };

    return (
        <Select
            options={options}
            value={selectedCountry ? { value: selectedCountry, label: selectedCountry } : null}
            onChange={handleChange}
            placeholder="Select Country"

        />
    );
}

export default CountrySelector;
