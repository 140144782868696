


import React, { useState, useEffect, useContext, useRef } from 'react';
import { UserAuth, userData } from '../context/AuthContext';
import { Container, Row, Col, Card, Button, Carousel, CardFooter, Navbar, Nav, Form, ButtonGroup, Alert, Spinner } from 'react-bootstrap';
import {
    getFirestore, collection, query, where, onSnapshot, orderBy, getDocs,
    doc, setDoc, updateDoc, addDoc, limit, deleteDoc
} from 'firebase/firestore';
import { Link, useLocation } from 'react-router-dom';
import { storage, db } from '../firebase'; // Import Firebase configurations
import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage"
import Chart from 'chart.js/auto';
import { Bar } from 'react-chartjs-2';
import ShareModal from '../components/ShareModel'; // Import the modal component

import PricingPage from '../components/PricingPage';

const MAX_IMAGE_SIZE_MB = 2; // Maximum allowed image size in MB
const MAX_UPLOADS_PER_DAY = 5; // Maximum allowed uploads per day

function usePrevious(value) {
    const ref = useRef();
    useEffect(() => {
        ref.current = value;
    });
    return ref.current;
}

function formatLabel(label) {
    return label
        // Replace underscores and hyphens with spaces
        .replace(/[_-]/g, ' ')
        // Split the string into words, capitalize the first letter of each, and join them back
        .split(' ')
        .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
        .join(' ');
}



const BreedDetector = () => {
    const { user, userData } = UserAuth();
    const [selectedImage, setSelectedImage] = useState(null);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');
    const [uploadsToday, setUploadsToday] = useState(0);
    const [userUploads, setUserUploads] = useState([]);
    const [showShareModal, setShowShareModal] = useState(false);
    const previousUploads = usePrevious(userUploads);

    const [hasActiveSubscription, setHasActiveSubscription] = useState(false);

    useEffect(() => {
        // Function to check if the user has an active subscription
        async function checkSubscriptionStatus() {
            try {
                // Get the user's token (assuming you have a way to access the user object)
                const token = await user.getIdToken();

                // Include user's email in the payload
                const payload = {
                    email: userData.email, // Assuming userData.email is available
                };

                // Make the API request to check the subscription status
                const headers = {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`,
                };

                const response = await fetch('https://us-central1-petshome-da28b.cloudfunctions.net/api/private/subscription', {
                    method: 'POST', // or 'GET' if your endpoint expects a GET request
                    headers: headers,
                    body: JSON.stringify(payload), // Include the email in the payload
                });

                if (!response.ok) {
                    throw new Error(`Network response was not ok (Status: ${response.status})`);
                }

                const data = await response.json();

                // Check if the response indicates an active subscription
                if (data && data.message === "Customer has an active subscription") {
                    setHasActiveSubscription(true);
                } else {
                    setHasActiveSubscription(false);
                }
            } catch (error) {
                console.error('Error checking subscription status:', error);
            }
        }

        // Call the function to check subscription status when the component mounts
        checkSubscriptionStatus();
    }, [user, userData.email]);

    useEffect(() => {
        if (user) {
            // Reference to the breeds collection
            const breedCollectionRef = collection(db, 'breeds');

            // Query for today's uploads to enforce the daily limit
            const today = new Date();
            today.setHours(0, 0, 0, 0);
            const todaysUploadsQuery = query(
                breedCollectionRef,
                where('author_Id', '==', user.uid),
                where('create_date', '>=', today.getTime())
            );

            // Query for all past uploads to display
            const allUploadsQuery = query(
                breedCollectionRef,
                where('author_Id', '==', user.uid),
                orderBy('create_date', 'desc')
            );

            // Real-time subscription for today's uploads
            const unsubscribeToday = onSnapshot(todaysUploadsQuery, (snapshot) => {
                setUploadsToday(snapshot.docs.length);
            }, (error) => {
                console.error('Error fetching today\'s uploads:', error);
            });

            // Real-time subscription for all uploads
            const unsubscribeAll = onSnapshot(allUploadsQuery, (snapshot) => {
                const uploads = snapshot.docs.map((doc) => ({
                    id: doc.id,
                    ...doc.data(),
                }));

                // Process and save chartData for each upload
                uploads.forEach(upload => {
                    if (upload.prediction) {
                        const chartData = formatPredictionsToChartData(upload.prediction.predictions);
                        upload.chartData = chartData;
                        upload.loadingChartData = false; // Assuming you handle loading state per upload
                    }
                });

                setUserUploads(uploads);
            }, (error) => {
                console.error('Error fetching all user uploads:', error);
            });

            // Clean up both subscriptions
            return () => {
                unsubscribeToday();
                unsubscribeAll();
            };
        }
    }, [user]); // Removed 'db' dependency as it's likely not a state or prop


    useEffect(() => {
        // If there are no previous uploads, don't show the modal
        if (!previousUploads) return;

        // Find any upload that has just received a successful predictionStatus
        const newlySuccessfulUpload = userUploads.find((upload, index) => {
            const wasPreviouslyUnsuccessful =
                previousUploads[index] && previousUploads[index].predictionStatus === 0;
            return wasPreviouslyUnsuccessful && upload.predictionStatus === 1;
        });

        // If there's a newly successful upload, show the modal
        if (newlySuccessfulUpload) {
            setShowShareModal(true);
        }
    }, [userUploads, previousUploads]);

    // Helper function to format predictions into chartData
    // Helper function to format predictions into chartData
    function formatPredictionsToChartData(predictions) {

        // Check if predictions is defined and is an array
        if (!Array.isArray(predictions)) {
            // Return some default chartData or handle the case as you see fit
            return {
                labels: [],
                datasets: [{
                    label: 'Breed Probability',
                    backgroundColor: 'rgba(75, 192, 192, 0.6)',
                    data: [],
                }],
            };
        }

        const sortedPredictions = predictions.sort((a, b) => b.probability - a.probability);
        const topPredictions = sortedPredictions.slice(0, 5);
        const labels = topPredictions.map(p => p.tagName);
        const data = topPredictions.map(p => p.probability);

        return {
            labels: labels,
            datasets: [{
                label: 'Probability',
                backgroundColor: 'rgba(75, 192, 192, 0.6)',
                data: data,
            }],
        };
    }




    const handleImageUpload = async (e) => {
        const file = e.target.files[0];

        if (file) {
            // Check if the file is a valid image (JPG or PNG)
            if (file.type === 'image/jpeg' || file.type === 'image/png') {
                // Check if the file size is within the limit
                if (file.size / 1024 / 1024 > MAX_IMAGE_SIZE_MB) {
                    setError(`Image size exceeds the maximum limit of ${MAX_IMAGE_SIZE_MB} MB.`);
                    return;
                }

                // Check if the user has reached the daily upload limit
                if (uploadsToday >= MAX_UPLOADS_PER_DAY) {
                    setError('You have reached the maximum limit of uploads for today.');
                    return;
                }

                setError('');
                setLoading(true);

                try {
                    // Create a new document in Firestore
                    const newBreedDoc = {
                        predictionStatus: 0,
                        title: user.uid,
                        author_Id: user.uid,
                        create_date: Date.now(),
                        images: {
                            main_image: '',
                        },
                        model_type: '',
                        prediction: {},
                        notes: '',
                        user_input_value: 0,
                    };

                    const breedCollectionRef = collection(db, 'breeds');
                    const docRef = await addDoc(breedCollectionRef, newBreedDoc);

                    // Upload the selected image to Firebase Storage
                    const imageField = 'main_image'; // Change this to specify the image field
                    const storageRef = ref(
                        storage,
                        `petshome/predictions/${docRef.id}/${imageField}.jpg`
                    );

                    let uploadTask = uploadBytesResumable(storageRef, file);

                    uploadTask.on(
                        'state_changed',
                        (snapshot) => {
                            const progress = Math.round(
                                (snapshot.bytesTransferred / snapshot.totalBytes) * 100
                            );
                            // console.log(progress);
                        },
                        (error) => {
                            console.error('Error uploading image:', error);
                            setError('Error uploading image.');
                        },
                        () => {
                            getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
                                // console.log(downloadURL);

                                const breedDocRef = doc(db, 'breeds', docRef.id);

                                updateDoc(breedDocRef, {
                                    [`images.${imageField}`]: downloadURL,
                                    URL: downloadURL,
                                });

                                // Update the uploads for today
                                setUploadsToday(uploadsToday + 1);
                                setLoading(false);
                            });
                        }
                    );
                } catch (error) {
                    console.error('Error creating Firestore document:', error);
                    setError('Error creating Firestore document.');
                    setLoading(false);
                }
            } else {
                setError('Please select a valid JPG or PNG image.');
            }
        }
    };

    const handleDelete = async (id) => {
        try {
            // Delete the document from Firestore
            await deleteDoc(doc(db, 'breeds', id));
            // Remove the deleted document from the userUploads state
            setUserUploads((prevUploads) => prevUploads.filter((upload) => upload.id !== id));
        } catch (error) {
            console.error('Error deleting document:', error);
        }
    };

    return (
        <Container>


            <Row>



                <Col md={12} className="p-4">
                    <h2 className="my-4">Pet Breed Detector</h2>


                    <p className="">
                        Pet Breed Detector is a cutting-edge tool designed to identify your
                        dog or cat's breed effortlessly. Using advanced AI technology, simply
                        upload an image of your pet, and our algorithm will accurately
                        determine their breed.
                    </p>
                    <hr />
                </Col>
                <Col className="text-center m-auto">


                    {user && userData ? (
                        <>
                            {error && <Alert variant="danger">{error}</Alert>}
                            {loading && (
                                <Col className="text-center mt-4">
                                    <Spinner animation="border" role="status">
                                        <span className="visually-hidden">Loading...</span>
                                    </Spinner>
                                </Col>
                            )}


                            {userData?.prediction_count > 2 && !hasActiveSubscription ? (
                                <>
                                    <Alert variant="primary">
                                        We rely on users' support to keep services running. To continue using our service, please subscribe now.
                                    </Alert>
                                    <PricingPage />
                                </>
                            ) : <Form.Group
                                controlId="imageUploadMain"
                                className="mt-0 text-center"
                                style={{ width: '18rem' }}
                            >
                                <Form.Label>Upload Pet Image (Cat or Dog)</Form.Label>
                                <Form.Control
                                    type="file"
                                    accept=".jpg, .jpeg, .png"
                                    onChange={handleImageUpload}
                                    required
                                    aria-required="true"
                                />
                            </Form.Group>
                            }


                        </>
                    ) : (

                        <>


                            <Row>

                                <Col md={6} className=' p-4'>
                                    <div className="text-center mt-4 ">
                                        <p>
                                            <Link
                                                type="button"
                                                className="btn btn-outline-success my-2 my-sm-0 btn-sm"
                                                to='/signin'
                                            >
                                                Sign in / Sign up
                                            </Link>
                                        </p>

                                        <p>At Pets Home App, we prioritize creating a community dedicated to responsible pet ownership and fostering genuine connections between pet lovers, rehomers, and breeders. To ensure the exclusivity of our platform and maintain a high standard of engagement, we require all users to undergo authentication. By authenticating users, we streamline the process, filtering out time-wasters and ensuring that our platform remains a space for serious buyers, rehomers, and breeders to connect efficiently and meaningfully. This authentication process not only enhances the quality of interactions but also reinforces our commitment to promoting responsible pet care practices throughout our community.</p>




                                    </div>
                                </Col>

                                <Col md={6} className=' p-4'>

                                    <p className="">Experience a more convenient way to connect with potential adopters. Our mobile app offers features like instant notifications when someone contacts you, easy photo uploads, and the ability to share videos of your pet's charm.</p>
                                    <p className='text-center font-weight-bold text-uppercase'>Download the app now:</p>
                                    <div className="d-flex justify-content-center">
                                        <a style={{ width: '130px', height: 'auto' }} href="https://play.google.com/store/apps/details?id=com.app.petshome" target="_blank" rel="noopener noreferrer" className="mr-3"><img src="/google-play-badge.svg" alt="Google Play" /></a>
                                        <a style={{ width: '130px', height: 'auto' }} href="https://apps.apple.com/us/app/pets-home-app/id1549828591?pt=581834&ct=desktop&mt=8" target="_blank" rel="noopener noreferrer"><img src="/app-store-badge.svg" alt="App Store" /></a>
                                    </div>

                                    <div className="d-flex justify-content-center">
                                        <img style={{ width: '130px', height: 'auto' }} src="/android-adobe-express-qr-code.png" alt="Android QR Code" className="mr-3" />
                                        <img style={{ width: '130px', height: 'auto' }} src="/ios-express-qr-code.png" alt="iOS QR Code" />
                                    </div>
                                    <p className="text-center mt-3">Scan the QR codes below for quick access:</p>


                                </Col>
                            </Row>
                            <div className="text-center mt-4 ">

                            </div>
                        </>



                    )}




                </Col>

            </Row>
            {user && userUploads.length > 0 && (
                <Row>
                    <Col md={12} className="mt-4">
                        <h2>Breeds Predictions</h2>
                    </Col>
                    {userUploads.map((upload) => (
                        <Col key={upload.id} md={4} className="mb-4">
                            <Card>
                                <Card.Img
                                    variant="top"
                                    src={upload.URL}
                                    style={{ height: '200px', objectFit: 'cover' }}
                                />
                                <Card.Body>
                                    {upload.predictionStatus === 0 ? (
                                        <>
                                            <div className="text-center" style={{ padding: '2rem' }}>
                                                <Spinner animation="border" role="status">
                                                    <span className="visually-hidden">Loading...</span>
                                                </Spinner>
                                            </div>
                                            <div className="text-center text-muted">Processing...</div>
                                        </>
                                    ) : (
                                        <>
                                            <Card.Title>
                                                {upload.chartData && upload.chartData.labels && upload.chartData.labels.length > 0 && (
                                                    <span className='text-success'> {formatLabel(upload.chartData.labels[0])} ({upload.model_type})</span>
                                                )}
                                            </Card.Title>
                                            {!upload.loadingChartData && (
                                                <Bar
                                                    data={upload.chartData}
                                                    options={{ maintainAspectRatio: true }}
                                                />
                                            )}
                                        </>
                                    )}
                                </Card.Body>
                                <Card.Footer>
                                    <Button
                                        className='btn btn-sm'
                                        variant="danger"
                                        onClick={() => handleDelete(upload.id)}
                                        block
                                    >
                                        <i className="fa fa-trash"></i>
                                    </Button>
                                </Card.Footer>
                            </Card>

                        </Col>
                    ))}
                </Row>
            )}

            <ShareModal show={showShareModal} onClose={() => setShowShareModal(false)} />

        </Container>
    );
};

export default BreedDetector;
