import * as React from 'react';

function PricingPage() {
    // Paste the stripe-pricing-table snippet in your React component
    return (
        // <stripe-pricing-table pricing-table-id="prctbl_1OHY0eGm5zuN7vxO8YPmmxvX"
        //     publishable-key="pk_live_51OHXmhGm5zuN7vxOTnelLXqdwjmM2wiEdmlZYvK82Iv2WkVXhw39EOPxHLiEIPwPWXp0IPj8zzj2NGRa18D8olhB002IVkmeSx">
        // </stripe-pricing-table>

        <stripe-pricing-table pricing-table-id="prctbl_1OJa7nGm5zuN7vxOiLY5YEeW"
            publishable-key="pk_live_51OHXmhGm5zuN7vxOTnelLXqdwjmM2wiEdmlZYvK82Iv2WkVXhw39EOPxHLiEIPwPWXp0IPj8zzj2NGRa18D8olhB002IVkmeSx">
        </stripe-pricing-table>
    );
}

export default PricingPage;