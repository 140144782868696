import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { UserAuth } from '../context/AuthContext';
import { Navbar, Nav, Container } from 'react-bootstrap';

const Navigation = () => {
    const { user, logOut } = UserAuth();
    const location = useLocation(); // Hook to access the current location

    const handleSignOut = async () => {
        try {
            await logOut();
        } catch (error) {
            console.log(error);
        }
    };

    return (
        <Navbar sticky="top" className="bg-white" expand="lg" variant="light">
            <Container fluid>
                <Navbar.Brand href="/" className="d-flex align-items-center">
                    <img src="android-chrome-192x192.png" width="50" height="50" className="d-inline-block align-top mr-1" alt="" />
                    <span>Pets Home: Buy & Sell Pets<small className='d-none d-sm-block' style={{ fontSize: 12 }}> Effortless Pet Rehoming Online </small></span>
                </Navbar.Brand>
                <Navbar.Toggle aria-controls="basic-navbar-nav" />

                <Navbar.Collapse id="basic-navbar-nav">
                    <Nav className="mr-auto">
                        <Nav.Link className='mr-2' as={Link} to='/'><i className="fa fa-home" aria-hidden="true"></i>  Home</Nav.Link>
                        <Nav.Link className='mr-2' as={Link} to='/findpets'><i className="fa fa-search" aria-hidden="true"></i> Find Pets</Nav.Link>
                        <Nav.Link className='mr-2' as={Link} to='/breed-detector'><i className="fa fa-magic" aria-hidden="true"></i> Breed Detector</Nav.Link>
                        <Nav.Link className='mr-2' as={Link} to='/pet-advisor'><i className="fa fa-user-md" aria-hidden="true"></i> Pet Advisor</Nav.Link>
                        <Nav.Link className='mr-2' as={Link} to='/videos'><i className="fa fa-play" aria-hidden="true"></i> Videos</Nav.Link>
                        {/* <Nav.Link className='mr-2' as={Link} to='https://shop.petshome.app/'><i className="fa fa-shop" aria-hidden="true"></i> Shop</Nav.Link> */}

                        <Nav.Link className='mr-2' as={Link} to='https://blog.petshome.app/'><i className="fa fa-blog" aria-hidden="true"></i> Blog</Nav.Link>


                        {user?.uid ? (
                            <Nav.Link as={Link} to='/account'><i className="fa fa-user" aria-hidden="true"></i> Account</Nav.Link>
                        ) : null}
                    </Nav>
                    <Navbar.Text>
                        <Link to='/createadvert' className="btn btn-success text-dark my-2 my-sm-0 btn-xl mr-2">
                            <i className="fas fa-plus"></i> Post Free Ad
                        </Link>
                    </Navbar.Text>
                    <Navbar.Text>
                        {user?.uid ? (
                            <button className="btn text-dark my-2 my-sm-0 btn-sm" onClick={handleSignOut}>Logout</button>
                        ) : (
                            <Link type="button" className="btn text-dark my-2 my-sm-0 btn-sm" to='/signin'>Sign in / Sign up</Link>
                        )}
                    </Navbar.Text>
                </Navbar.Collapse>
            </Container>
        </Navbar>


    );
};

export default Navigation;
