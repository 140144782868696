import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Card, Modal, Button } from 'react-bootstrap';

function PrivacyPolicy() {


    return (
        <Container>
            <Row>
                <Container>
                    <h1 class="cover-heading"> Privacy Policy
                    </h1>

                    <p><strong>Introduction</strong><br />
                        Our privacy is paramount to us at Pets Home. We are committed to respecting your privacy
                        regarding any information we may collect from you across our mobile application, Pets Home, and
                        our website, <a href="http://www.petshome.app">http://www.petshome.app</a>, along with other
                        sites we own and operate.</p>

                    <p><strong>Information Collection and Use</strong><br />
                        We collect information for various purposes to provide and improve our Service to you. Types of
                        Data collected include:</p>
                    <ul>
                        <li><strong>Personal Data:</strong> While using our Service, we may ask you to provide us with
                            certain personally identifiable information that can be used to contact or identify you.
                            Personally identifiable information may include, but is not limited to, email address, name,
                            and phone number.</li>
                        <li><strong>Usage Data:</strong> This data is collected automatically when using the service.
                        </li>
                        <li><strong>Images and Content:</strong> Users have the ability to upload images and content
                            related to their pets. These images are used within the app to enhance the user experience.
                        </li>
                        <li><strong>Location:</strong> To find nearby ads.
                        </li>
                    </ul>

                    <p><strong>Why We Collect Data</strong><br />
                        We collect data to provide and enhance our services, respond to your requests/inquiries,
                        personalize your experience, and inform you about our services and features.</p>

                    <p><strong>Consent</strong><br />
                        By using our app or website, you consent to the collection and use of information in accordance
                        with this policy. We collect and use your information only with your knowledge and consent.</p>

                    <p><strong>Data Storage and Protection</strong><br />
                        The data we store will be protected within commercially acceptable means to prevent loss and
                        theft, as well as unauthorized access, disclosure, copying, use, or modification. However, we
                        cannot guarantee absolute security.</p>

                    <p><strong>Changes to This Privacy Policy</strong><br />
                        We may update our Privacy Policy from time to time. Thus, you are advised to review this page
                        periodically for any changes. We will notify you of any changes by posting the new Privacy
                        Policy on this page. These changes are effective immediately after they are posted on this page.
                    </p>

                    <p><strong>Contact Us</strong><br />
                        If you have any questions or suggestions about our Privacy Policy, do not hesitate to contact us
                        at admin@petshome.app.</p>
                </Container>
            </Row>
        </Container>
    );
}

export default PrivacyPolicy;
