import React, { useState, useEffect, useContext, useRef } from 'react';
import { Container, Form, Button, Col, Row, Alert, Spinner } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import {
    getFirestore, collection, query, where, onSnapshot, orderBy, getDocs,
    doc, setDoc, updateDoc, addDoc, limit, deleteDoc, getDoc
} from 'firebase/firestore';
import { format } from 'date-fns'
import { db } from '../firebase'; // Import Firebase configurations
import { UserAuth, userData } from '../context/AuthContext'; // Import your authentication context
import Filter from 'bad-words'; // A profanity filter library

import PricingPage from '../components/PricingPage';

const filter = new Filter();


const PetAdvisor = () => {

    const { user, userData } = UserAuth();
    const [errorMessage, setErrorMessage] = useState('');
    const [loading, setLoading] = useState(false);
    const [messageLimitReached, setMessageLimitReached] = useState(false);
    const messageInputRef = useRef(null);
    const [messages, setMessages] = useState([]);
    const messagesEndRef = useRef(null); // For auto-scrolling

    const [createCollection, setCreateCollection] = useState(true);

    const [hasActiveSubscription, setHasActiveSubscription] = useState(false);

    useEffect(() => {
        // Function to check if the user has an active subscription
        async function checkSubscriptionStatus() {
            try {
                // Get the user's token (assuming you have a way to access the user object)
                const token = await user.getIdToken();

                // Include user's email in the payload
                const payload = {
                    email: userData.email, // Assuming userData.email is available
                };

                // Make the API request to check the subscription status
                const headers = {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`,
                };

                const response = await fetch('https://us-central1-petshome-da28b.cloudfunctions.net/api/private/subscription', {
                    method: 'POST', // or 'GET' if your endpoint expects a GET request
                    headers: headers,
                    body: JSON.stringify(payload), // Include the email in the payload
                });

                if (!response.ok) {
                    throw new Error(`Network response was not ok (Status: ${response.status})`);
                }

                const data = await response.json();

                // Check if the response indicates an active subscription
                if (data && data.message === "Customer has an active subscription") {
                    setHasActiveSubscription(true);
                } else {
                    setHasActiveSubscription(false);
                }
            } catch (error) {
                console.error('Error checking subscription status:', error);
            }
        }

        // Call the function to check subscription status when the component mounts
        checkSubscriptionStatus();
    }, [user, userData.email]);


    useEffect(() => {
        if (user?.uid) {
            setMessages([])
            console.log(user.uid)
            const chatChannelDocId = user.uid;
            const messagesRef = collection(db, "chat_ai", chatChannelDocId, "chat");
            const q = query(messagesRef, orderBy("utc", "asc"));

            const unsubscribe = onSnapshot(q, (snapshot) => {
                const fetchedMessages = snapshot.docs.map(doc => doc.data());
                setMessages(fetchedMessages);
                if (fetchedMessages.length > 0 && fetchedMessages[fetchedMessages.length - 1].senderId === 'AI') {
                    setLoading(false);
                }
            });

            return unsubscribe; // Cleanup listener on component unmount
        }
    }, [user?.uid]);


    useEffect(() => {
        // Get the messages container div
        const messagesContainer = document.querySelector('.messages-list');
        if (messagesContainer) {
            // Scroll to the bottom
            messagesContainer.scrollTop = messagesContainer.scrollHeight;
        }
    }, [messages.length]); // Depend on the length of the messages array


    useEffect(() => {
        if (user?.uid) {
            // Check for message limit
            const today = new Date();
            today.setHours(0, 0, 0, 0);
            const messagesRef = collection(db, "chat_ai", user.uid, "chat");
            const q = query(messagesRef, where("utc", ">=", today.getTime()));

            getDocs(q).then(querySnapshot => {
                const messagesToday = querySnapshot.size;
                setMessageLimitReached(messagesToday >= 10);
            });
        }
    }, [user?.uid]);


    const isValidTextInput = (text, lenght) => {
        // Remove leading and trailing whitespaces
        text = text.trim();

        // Use a regex pattern to split the string into words
        const words = text.split(/\s+/);

        // Check if there are at least three words
        return words.length >= lenght;
    };


    const handleInputChange = (e) => {
        const { name, value } = e.target;

        // Define character limits for the fields
        const maxMessageLength = 200;
        const validTextPattern = /^[a-zA-Z.,'"\s-]+$/;

        // Check for profanity

        if (name === "message") {
            if (value.length > maxMessageLength) {
                // Display an error message for the field if it exceeds the limit
                setErrorMessage(`${name} must be no more than ${maxMessageLength} characters.`);
            } else if (!validTextPattern.test(value) && value !== "") {
                // Display an error message if the input contains invalid characters
                setErrorMessage(`${name} contains invalid characters.`);
            } else {
                // Clear the error message and update the state with the valid input
                setErrorMessage("");

            }

        }
    };


    const handleSubmitMessage = async () => {
        let message = messageInputRef.current.value;
        message = message.trim();


        if (!user) {
            setErrorMessage('Session expired');
            return;
        }

        if (message.length > 200) {
            // Display an error message for the field if it exceeds the limit
            setErrorMessage(`Question must be no more than 200 characters.`);
        }
        const isMessageClean = !filter.isProfane(message);
        if (!isMessageClean) {
            setErrorMessage('Please avoid using profanity.');
            return false;
        }

        if (!isValidTextInput(message, 3)) {
            // Display an error message for the title field
            setErrorMessage('Question must contain at least 3 words.');
            return false;
        }

        if (message !== '') {

            const currentDate = new Date();
            const date = format(currentDate, 'dd/MM/yyyy hh:mm:ss');

            if (createCollection) {
                try {

                    const chatChannelData = {

                        "utc": Date.now()
                    };
                    console.log("chatChannelData:", chatChannelData)
                    // Add the chat channel document



                    await setDoc(doc(db, 'chat_ai', user.uid), chatChannelData);
                    console.log('created chat channel')
                    setCreateCollection(false); // Set createCollection to false after creating the chat channel
                } catch (error) {
                    console.error('Error creating chat channel:', error);
                }
            }

            try {
                // Create a new message document
                const messageData = {
                    "senderId": user.uid,
                    "time": date,
                    "text": message,
                    "unread": true,
                    "utc": Date.now(),
                    // Add other message data as needed
                };


                const messagesRef = collection(db, "chat_ai", user.uid, "chat");
                await addDoc(messagesRef, messageData);

                console.log("message saved")

                // Check if the last message is from AI and set loading accordingly
                if (messages.length > 0 && messages[messages.length - 1].senderId === 'AI') {
                    setLoading(true);
                }

                // Clear the message input field or take other necessary actions
                messageInputRef.current.value = '';

            } catch (error) {
                console.error('Error sending message:', error);
            }
        }
    };



    const closeChatPanel = () => {
        setMessages([]);
        setCreateCollection(true)

    };
    return (
        <Container>
            <Row>
                <Col md={12} className=' p-4'>
                    <h2 className="my-4">Pet Advisor</h2>
                    <p>Meet Pet Advisor, the latest addition to our Pets Home app. Powered by AI, Pet Advisor is your go-to companion for all things pet-related, offering expert guidance on adoption, care, and nutrition. Whether you're a seasoned pet owner or new to the world of pet parenthood, Pet Advisor is here to make your journey smoother and more enjoyable. Download the Pets Home app today and let Pet Advisor help you provide the best care for your beloved companion.</p>
                </Col>
            </Row>

            {user ? (
                <>
                    <Row>
                        <Col>
                            <div

                            >
                                <Alert variant="warning" className="m-2">
                                    <small className="text-muted">
                                        This service provides general information about pet care and is not a substitute for veterinary advice. For any concerns about your pet's health, please consult a licensed veterinarian. Use of Pet Advisor is solely at your own risk.
                                    </small>
                                </Alert>


                                {/* Middle: Chat Messages */}


                                {userData?.chat_ai_count > 3 && !hasActiveSubscription ? (
                                    <>
                                        <Alert variant="primary">
                                            We rely on users' support to keep services running. To continue using our service, please subscribe now.
                                        </Alert>
                                        <PricingPage />
                                    </>
                                ) :

                                    <>
                                        <div className="messages-list flex-grow-1 p-3 bg-light mb-4 p-4" style={{
                                            maxHeight: "350px",
                                            height: "350px",
                                            paddingBottom: "50px !important",
                                            overflowY: "scroll", // Corrected here
                                        }}>
                                            {messages.map((message, index) => (
                                                <div
                                                    style={{ minWidth: '55%', maxWidth: '70%', clear: 'both', }}
                                                    key={index}
                                                    className={`mb-2 ${message.senderId === user.uid ? 'float-left' : 'float-right'
                                                        }`}
                                                >
                                                    <div
                                                        className={`rounded p-3 ${message.senderId !== user.uid ? 'bg-secondary text-white' : 'bg-info text-white'
                                                            }`}
                                                    // Limit message width
                                                    >

                                                        <p className="mb-0">
                                                            {message.senderId === 'AI' ? 'Response : ' : 'Question : '}
                                                            {message.text}
                                                        </p>

                                                        <small style={{ fontSize: 11 }}>
                                                            {new Date(message.utc).toLocaleString(undefined, {
                                                                year: 'numeric',
                                                                month: 'short',
                                                                day: 'numeric',
                                                                hour: 'numeric',
                                                                minute: 'numeric',
                                                            })}
                                                        </small>
                                                    </div>
                                                </div>
                                            ))}
                                            <div ref={messagesEndRef} />
                                        </div>




                                        {/* Bottom: Input Area */}
                                        <div className="p-3 bg-light border-top position-fixed h-10 d-flex flex-column "
                                            style={{
                                                bottom: 0,
                                                right: 0,
                                                width: '100%',
                                                backgroundColor: '#f8f9fa',
                                                zIndex: 99999,
                                                overflowY: 'auto',
                                                boxShadow: '-2px 0 5px rgba(0, 0, 0, 0.3)'
                                            }}>

                                            <Container>
                                                {errorMessage && (
                                                    <Alert className='mt-4' variant="warning" onClose={() => setErrorMessage('')} dismissible>
                                                        {errorMessage}
                                                    </Alert>
                                                )}

                                                {messageLimitReached && (
                                                    <Alert variant="warning">You have reached your daily question limit. Try again tomorrow.</Alert>
                                                )}

                                                <div className="message-input-area text-center">
                                                    <Form.Control
                                                        type="text"
                                                        placeholder="Type your question here..."
                                                        ref={messageInputRef}
                                                        onChange={handleInputChange}
                                                        maxLength={200}
                                                        disabled={loading || messageLimitReached}
                                                    />
                                                    <Button
                                                        variant="primary"
                                                        onClick={handleSubmitMessage}
                                                        disabled={loading || messageLimitReached}
                                                        className='mt-4 '
                                                    >
                                                        {loading ? (
                                                            <>
                                                                <Spinner
                                                                    as="span"
                                                                    animation="border"
                                                                    size="sm"
                                                                    role="status"
                                                                    aria-hidden="true"
                                                                /> loading ...
                                                            </>
                                                        ) : "Ask Question"}
                                                    </Button>

                                                </div>
                                                <div ref={messagesEndRef} />

                                            </Container>


                                        </div>
                                    </>
                                }



                            </div>

                        </Col>
                    </Row>

                </>) :

                <>
                    <Row>

                        <Col md={6} className=' p-4'>
                            <div className="text-center mt-4 ">
                                <p>
                                    <Link
                                        type="button"
                                        className="btn btn-outline-success my-2 my-sm-0 btn-sm"
                                        to='/signin'
                                    >
                                        Sign in / Sign up
                                    </Link>
                                </p>

                                <p>At Pets Home App, we prioritize creating a community dedicated to responsible pet ownership and fostering genuine connections between pet lovers, rehomers, and breeders. To ensure the exclusivity of our platform and maintain a high standard of engagement, we require all users to undergo authentication. By authenticating users, we streamline the process, filtering out time-wasters and ensuring that our platform remains a space for serious buyers, rehomers, and breeders to connect efficiently and meaningfully. This authentication process not only enhances the quality of interactions but also reinforces our commitment to promoting responsible pet care practices throughout our community.</p>




                            </div>
                        </Col>

                        <Col md={6} className=' p-4'>

                            <p className="">Experience a more convenient way to connect with potential adopters. Our mobile app offers features like instant notifications when someone contacts you, easy photo uploads, and the ability to share videos of your pet's charm.</p>
                            <p className='text-center font-weight-bold text-uppercase'>Download the app now:</p>
                            <div className="d-flex justify-content-center">
                                <a style={{ width: '130px', height: 'auto' }} href="https://play.google.com/store/apps/details?id=com.app.petshome" target="_blank" rel="noopener noreferrer" className="mr-3"><img src="/google-play-badge.svg" alt="Google Play" /></a>
                                <a style={{ width: '130px', height: 'auto' }} href="https://apps.apple.com/us/app/pets-home-app/id1549828591?pt=581834&ct=desktop&mt=8" target="_blank" rel="noopener noreferrer"><img src="/app-store-badge.svg" alt="App Store" /></a>
                            </div>

                            <div className="d-flex justify-content-center">
                                <img style={{ width: '130px', height: 'auto' }} src="/android-adobe-express-qr-code.png" alt="Android QR Code" className="mr-3" />
                                <img style={{ width: '130px', height: 'auto' }} src="/ios-express-qr-code.png" alt="iOS QR Code" />
                            </div>
                            <p className="text-center mt-3">Scan the QR codes below for quick access:</p>


                        </Col>
                    </Row>
                    <div className="text-center mt-4 ">

                    </div>

                </>

            }
        </Container>
    );
}

export default PetAdvisor;
