import React, { useState, useEffect, useContext } from 'react';
import { Container, Form, Button, Col, Row, Alert } from 'react-bootstrap';
import { Link, useLocation } from 'react-router-dom';
import {
    getFirestore, collection, query, where, onSnapshot, orderBy, getDocs,
    doc, setDoc, updateDoc, addDoc, limit, deleteDoc
} from 'firebase/firestore';

import { storage, db } from '../firebase'; // Import Firebase configurations
import { pet_types } from '../data/pet_types'; // Adjust the path to match your file location
import { UserAuth, userData } from '../context/AuthContext'; // Import your authentication context
import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage"
import Filter from 'bad-words'; // A profanity filter library
const filter = new Filter();

function CreateAdvert() {
    const { user, userData } = UserAuth();
    const [advertCreated, setAdvertCreated] = useState(false);
    const [_currentAdvertDocId, setCurrentAdvertDocId] = useState(null);


    const [locationSavedSuccessfully, setLocationSavedSuccessfully] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');



    useEffect(() => {
        // Check if the cached location is available
        if (!userData.location || Object.keys(userData.location).length != 0)
            setLocationSavedSuccessfully(true)

        if (!userData.location || Object.keys(userData.location).length === 0)
            if (navigator.geolocation && navigator.geolocation.lastPosition) {
                const { latitude, longitude } = navigator.geolocation.lastPosition.coords;
                // Use the cached latitude and longitude here
                updateUserLocation(latitude, longitude);
            } else {
                // If there's no cached location, request the current location
                navigator.geolocation.getCurrentPosition(
                    (position) => {
                        // Handle successful location permission here
                        const { latitude, longitude } = position.coords;
                        updateUserLocation(latitude, longitude);
                        setErrorMessage('');
                    },
                    (error) => {
                        // Handle location permission denied or other errors
                        if (error.code === 1) {
                            console.log('Location permission denied. Provide guidance here.');
                            setErrorMessage('Location permission denied.');

                        } else {
                            console.error('Error getting location:', error.message);
                            setErrorMessage('Error getting location. Please try again later.');
                        }
                    }
                );
            }

    }, [user, userData.location]);

    const updateUserLocation = async () => {

        if (!user) {
            return;
        }

        if (!navigator.geolocation) {

            setErrorMessage('Geolocation is not supported by your browser');
            return;
        }

        // Request location permission from the user
        navigator.geolocation.getCurrentPosition(
            async (position) => {
                const { latitude, longitude } = position.coords;

                // Update the user's location in Firestore

                // Assuming you have the user's UID
                const userDocRef = doc(db, 'users', user.uid);

                try {
                    await updateDoc(userDocRef, {
                        location: {
                            coordinates: [latitude, longitude],
                            type: 'Point',
                        },
                    });
                    // console.log('User location updated successfully');
                    setLocationSavedSuccessfully(true);

                } catch (error) {
                    // console.error('Error updating user location: ', error);
                    setErrorMessage('Error updating user location');
                }
            },
            (error) => {
                console.error('Error getting user location: ', error);
                // setErrorMessage('Error getting user location');
            }
        );
    };


    const currentUtcTimestamp = Math.floor(Date.now() / 1000);

    const [advertData, setAdvertData] = useState({
        advertStatus: 0,
        title: '',
        description: '',
        advert_type: 'For Sale',
        pet_type: '',
        pet_breed: '',
        pet_age: '',
        asking_price: '',
        showLocation: 0,
        showPhone: 0,
        create_date: new Date().toISOString(),
        utc: currentUtcTimestamp,
        images: {
            main_image: '',
            image_1: '',
            image_2: '',
        },
    });

    const [imageFiles, setImageFiles] = useState({
        main_image: null,
        image_1: null,
        image_2: null,
    });

    useEffect(() => {
        if (imageFiles.main_image) {
            handleImageUpload('main_image');
        }
    }, [imageFiles.main_image]);

    useEffect(() => {
        if (imageFiles.image_1) {
            handleImageUpload('image_1');
        }
    }, [imageFiles.image_1]);

    useEffect(() => {
        if (imageFiles.image_2) {
            handleImageUpload('image_2');
        }
    }, [imageFiles.image_2]);







    const [petTypes, setPetTypes] = useState([]);
    const [breedTypes, setBreedTypes] = useState([]);


    useEffect(() => {
        // Set the pet types data from the imported JavaScript file
        setPetTypes(pet_types.map((type) => type.name));
    }, []);



    useEffect(() => {
        // When the selected "Pet Type" changes, update the breed options
        if (advertData.petType) {
            const selectedPetTypeData = pet_types.find(
                (type) => type.name === advertData.petType
            );
            if (selectedPetTypeData) {
                setBreedTypes(selectedPetTypeData.breeds);
            }
        } else {
            // Clear the breed options if no "Pet Type" is selected
            setBreedTypes([]);
        }
    }, [advertData.petType]);


    const isValidTextInput = (text, lenght) => {
        // Remove leading and trailing whitespaces
        text = text.trim();

        // Use a regex pattern to split the string into words
        const words = text.split(/\s+/);

        // Check if there are at least three words
        return words.length >= lenght;
    };


    const handleInputChange = (e) => {
        const { name, value } = e.target;

        // Define character limits for the fields
        const maxTitleLength = 50;
        const maxDescriptionLength = 500;

        // Define a regular expression pattern to allow letters, numbers, spaces, and some common punctuation
        const validTextPattern = /^[a-zA-Z.,'"\s-]+$/;

        // Check for profanity

        if (name === "title") {






            if (value.length > maxTitleLength) {
                // Display an error message for the field if it exceeds the limit
                setErrorMessage(`${name} must be no more than ${maxTitleLength} characters.`);
            } else if (!validTextPattern.test(value) && value !== "") {
                // Display an error message if the input contains invalid characters
                setErrorMessage(`${name} contains invalid characters.`);
            } else {
                // Clear the error message and update the state with the valid input
                setErrorMessage("");
                setAdvertData({ ...advertData, [name]: value });
            }

        } else if (name === "description") {


            if (value.length > maxDescriptionLength) {
                // Display an error message for the field if it exceeds the limit
                setErrorMessage(`${name} must be no more than ${maxDescriptionLength} characters.`);
            } else if (!validTextPattern.test(value) && value !== "") {
                // Display an error message if the input contains invalid characters
                setErrorMessage(`${name} contains invalid characters.`);
            } else {
                // Clear the error message and update the state with the valid input
                setErrorMessage("");
                setAdvertData({ ...advertData, [name]: value });
            }


        } else {
            // For other fields, update the state without restrictions
            setAdvertData({ ...advertData, [name]: value });
        }
    };








    const handleSubmit = async (e) => {
        e.preventDefault();

        if (!user) {
            setErrorMessage('Session expired');
            return;
        }

        const isTitleClean = !filter.isProfane(advertData.title);
        if (!isTitleClean) {
            setErrorMessage('Please avoid using profanity.');
            return false;
        }

        const isDescriptionClean = !filter.isProfane(advertData.description);
        if (!isDescriptionClean) {
            setErrorMessage('Please avoid using profanity.');
            return false;
        }


        if (!isValidTextInput(advertData.description, 10)) {
            // Display an error message for the title field
            setErrorMessage('Description must contain at least 10 words.');
            return false;
        }


        if (!isValidTextInput(advertData.title, 3)) {
            // Display an error message for the title field
            setErrorMessage('Title must contain at least 3 words.');
            return false;
        }


        try {

            const updatedAdvertData = {
                ...advertData,
                author_Id: user.uid,
                country_code: userData.country_code,
                country_name: userData.country_name,
            };



            // Create the advert document in Firestore
            const collectionRef = collection(db, 'adverts');
            const advertRef = await addDoc(collectionRef, updatedAdvertData);
            const advertDocId = advertRef.id;
            setCurrentAdvertDocId(advertDocId)
            // console.log(user.uid)
            // console.log(advertDocId)
            // console.log(updatedAdvertData)


            setAdvertCreated(true); // Mark advert as created
        } catch (error) {
            console.error('Error creating advert: ', error);
        }
    };


    const handleImageUpload = async (imageField) => {
        if (!_currentAdvertDocId) {
            console.error('Advert document ID is not available');
            return;
        }



        const file = imageFiles[imageField];

        if (!file) {
            console.error(`No file selected for ${imageField}`);
            return;
        }

        // Check if the image size exceeds a certain limit (e.g., 5MB)
        const maxSizeInBytes = 5 * 1024 * 1024; // 5MB
        if (file.size > maxSizeInBytes) {
            console.error(`Image size exceeds the limit (${maxSizeInBytes} bytes)`);
            return;
        }

        // Compress and resize the image if it's very large
        const maxWidth = 800; // Maximum width for resized image
        const maxHeight = 800; // Maximum height for resized image
        const image = new Image();
        const reader = new FileReader();

        reader.onload = (e) => {
            image.src = e.target.result;

            image.onload = async () => {
                const canvas = document.createElement('canvas');
                let width = image.width;
                let height = image.height;

                if (width > maxWidth || height > maxHeight) {
                    const ratio = Math.min(maxWidth / width, maxHeight / height);
                    width *= ratio;
                    height *= ratio;
                }

                canvas.width = width;
                canvas.height = height;

                const ctx = canvas.getContext('2d');
                ctx.drawImage(image, 0, 0, width, height);

                canvas.toBlob(async (blob) => {

                    try {


                        const storageRef = ref(storage,
                            `petshome/adverts/${_currentAdvertDocId}/${imageField}.jpg`
                        );
                        let uploadTask = uploadBytesResumable(storageRef, blob);

                        uploadTask.on("state_changed",
                            (snapshot) => {
                                const progress =
                                    Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 100);
                                // setProgresspercent(progress);
                                console.log(progress)
                            },
                            (error) => {
                                // alert(error);
                                console.log(error)
                            },
                            () => {
                                getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
                                    // setImgUrl(downloadURL)
                                    // console.log(downloadURL)
                                    const advertDocRef = doc(db, 'adverts', _currentAdvertDocId);

                                    updateDoc(advertDocRef, {
                                        [`images.${imageField}`]: downloadURL,
                                    }).then(() => {

                                        const updatedAdvertData = {
                                            ...advertData,
                                            author_Id: user.uid,
                                            country_code: userData.country_code,
                                            country_name: userData.country_name,
                                        };

                                        setAdvertData({
                                            ...updatedAdvertData,
                                            images: {
                                                ...updatedAdvertData.images,
                                                [imageField]: downloadURL,
                                            },


                                        });

                                        // console.log(updatedAdvertData)

                                    })


                                });
                            }
                        );







                    } catch (error) {
                        console.error(`Error uploading ${imageField}: `, error);
                    }
                }, 'image/jpeg', 0.9); // Convert to JPEG with 90% quality
            };
        };

        reader.readAsDataURL(file);
    };




    return (
        <Container className='mt-4'>



            <Row>
                <Col md={6} className=' p-4'>
                    <h1 className="display-6">Find a Loving Home for Your Pet</h1>

                    <p className="lead">Maximize your pet's rehoming success by including crucial information like vaccination records, availability details, and insights into your pet's unique personality and habits. Allow your location to connect with local pet lovers. <span className='text-danger'>We will not publish the ad without location details.</span></p>
                    <hr></hr>
                    <p className="lead">Experience a more convenient way to connect with potential adopters. Our mobile app offers features like instant notifications when someone contacts you, easy photo uploads, and the ability to share videos of your pet's charm.</p>
                    <p className='text-center font-weight-bold text-uppercase'>Download the app now:</p>
                    <div className="d-flex justify-content-center">
                        <a style={{ width: '130px', height: 'auto' }} href="https://play.google.com/store/apps/details?id=com.app.petshome" target="_blank" rel="noopener noreferrer" className="mr-3"><img src="/google-play-badge.svg" alt="Google Play" /></a>
                        <a style={{ width: '130px', height: 'auto' }} href="https://apps.apple.com/us/app/pets-home-app/id1549828591" target="_blank" rel="noopener noreferrer"><img src="/app-store-badge.svg" alt="App Store" /></a>
                    </div>

                    <div className="d-flex justify-content-center">
                        <img style={{ width: '130px', height: 'auto' }} src="/android-adobe-express-qr-code.png" alt="Android QR Code" className="mr-3" />
                        <img style={{ width: '130px', height: 'auto' }} src="/ios-express-qr-code.png" alt="iOS QR Code" />
                    </div>
                    <p className="text-center mt-3">Scan the QR codes below for quick access:</p>


                </Col>
                <Col md={6} className='bg-light p-4 rounded'>
                    <h4>Create a Free Pet Rehoming Ad</h4>
                    <hr></hr>


                    {advertCreated ? (
                        <>


                            <div>
                                {Object.entries(advertData.images).map(([key, imageUrl], index) => (
                                    imageUrl && ( // Only render if imageUrl is not empty or null
                                        <img
                                            key={index}
                                            src={imageUrl}
                                            style={{
                                                borderRadius: '0px', // Add any other common styles here
                                                ...(key === 'main_image' && { width: '100%' }), // Full width for main_image
                                                ...(key === 'image_1' && { width: '50%' }), // 50% width for image_1
                                                ...(key === 'image_2' && { width: '50%' }), // 50% width for image_2
                                            }}
                                            alt={`Image ${index}`}
                                        />
                                    )
                                ))}
                            </div>




                            <Form.Group controlId="imageUploadMain" className="mt-4">
                                <Form.Label>Upload Main Image (Required)</Form.Label>
                                <Form.Control
                                    type="file"
                                    accept="image/*"
                                    onChange={(e) => {
                                        setImageFiles({ ...imageFiles, main_image: e.target.files[0] });
                                    }}
                                    required // Add the required attribute
                                    aria-required="true" // Add an accessibility attribute
                                />
                            </Form.Group>


                            <Form.Group controlId="imageUpload1" className="mt-4">
                                <Form.Label>Upload Image 1</Form.Label>
                                <Form.Control
                                    type="file"
                                    accept="image/*"
                                    onChange={(e) => {
                                        setImageFiles({ ...imageFiles, image_1: e.target.files[0] });
                                    }}

                                />
                            </Form.Group>

                            <Form.Group controlId="imageUpload2" className="mt-4">
                                <Form.Label>Upload Image 2</Form.Label>
                                <Form.Control
                                    type="file"
                                    accept="image/*"
                                    onChange={(e) => {
                                        setImageFiles({ ...imageFiles, image_2: e.target.files[0] });
                                    }}

                                />
                            </Form.Group>

                            <div className='text-center mt-4 mb-4'>
                                <Link
                                    to='/account'
                                    className={`btn btn-success text-light my-2 my-sm-0 btn-xl mr-2 ${!imageFiles.main_image ? 'disabled' : '' // Add the condition here
                                        }`}
                                >
                                    Finish
                                </Link>
                            </div>





                        </>
                    ) :
                        <>

                            <Form onSubmit={handleSubmit}>
                                {/* Add form fields for advert information */}
                                {/* Example: */}

                                <Form.Group controlId="petType" className='mt-4'>
                                    <Form.Label>Pet Type</Form.Label>
                                    <Form.Control
                                        as="select"
                                        name="pet_type"
                                        value={advertData.pet_type}
                                        onChange={handleInputChange}
                                        required
                                    >
                                        <option value="">Select Pet Type</option>
                                        {pet_types.map((type) => (
                                            <option key={type.name} value={type.name}>
                                                {type.name}
                                            </option>
                                        ))}
                                    </Form.Control>
                                </Form.Group>
                                {/* Select dropdown for petBreed */}
                                <Form.Group controlId="pet_breed" className='mt-4'>
                                    <Form.Label>Pet Breed</Form.Label>
                                    <Form.Control
                                        as="select"
                                        name="pet_breed"
                                        value={advertData.pet_breed}
                                        onChange={handleInputChange}
                                        required
                                    >
                                        <option value="">Select Pet Breed</option>
                                        {advertData.pet_type &&
                                            (pet_types
                                                .find((type) => type.name === advertData.pet_type)
                                                ?.breeds || ['All']) // Use ['All'] if breeds are not available
                                                .map((breed) => (
                                                    <option key={breed} value={breed}>
                                                        {breed}
                                                    </option>
                                                ))}
                                    </Form.Control>
                                </Form.Group>


                                <Form.Group controlId="title" className='mt-4' >
                                    <Form.Label>Ad Title</Form.Label>
                                    <Form.Control
                                        type="text"
                                        name="title"
                                        placeholder="Enter your title here..."
                                        value={advertData.title}
                                        onChange={handleInputChange}
                                        min="0" // Set the minimum value to 1
                                        max="1000" // Set the maximum value to 5
                                        required
                                    />
                                </Form.Group>

                                <Form.Group controlId="description" className='mt-4'>
                                    <Form.Label>Pet description</Form.Label>
                                    <Form.Control
                                        as="textarea"
                                        placeholder="Include vaccination records, availability, and your pet's habits to improve rehoming prospects."
                                        rows={3}
                                        name="description"
                                        value={advertData.description}
                                        onChange={handleInputChange}
                                        min="0" // Set the minimum value to 1
                                        max="1000" // Set the maximum value to 5
                                        required
                                    />
                                </Form.Group>

                                <Form.Group controlId="asking_price" className='mt-4' >
                                    <Form.Label>Rehome fee</Form.Label>
                                    <Form.Control
                                        type="number" // Use type="number" to accept only numbers
                                        name="asking_price"
                                        placeholder="Enter the rehome fee..."
                                        value={advertData.asking_price}
                                        onChange={handleInputChange}
                                        min="1" // Set the minimum value to 1
                                        max="10000" // Set the maximum value to 5
                                        required
                                    />
                                </Form.Group>
                                {errorMessage && (
                                    <Alert className='mt-4' variant="warning" onClose={() => setErrorMessage('')} dismissible>
                                        {errorMessage}
                                    </Alert>
                                )}









                                {/* Render the location permission guidance and "Try again" button */}
                                {user && !locationSavedSuccessfully && (
                                    <>
                                        {/* Show a message explaining why you need user location and how to enable it */}

                                        <ol className='mt-4'>
                                            <li>Open your browser settings.</li>
                                            <li>Navigate to the "Privacy" or "Site Settings" section.</li>
                                            <li>Find "Location" or "Location Services" and enable it.</li>
                                            <li>Refresh this page and click "Try again" below, or refresh page.</li>
                                            <li>It may take upto a minute sometime.</li>
                                        </ol>

                                        {/* Show the "Try again" button */}
                                        <Button className="btn btn-warning" onClick={updateUserLocation}>
                                            Try again
                                        </Button>
                                    </>
                                )}

                                {/* Render the "Allow Location" button or the "Create Advert" button based on user and location status */}
                                {user ? (
                                    locationSavedSuccessfully ? (
                                        <div className='text-center'>
                                            <Button variant="success" type="submit" className='mt-4'>
                                                Create Advert
                                            </Button>
                                        </div>
                                    ) : null
                                ) : (
                                    <p className='mt-4'>
                                        Please{' '}
                                        <Link type="button" className="btn btn-outline-success my-2 my-sm-0 btn-sm" to='/signin'>
                                            Sign in / Sign up
                                        </Link>{' '}
                                        to create an Ad.
                                    </p>
                                )}
                            </Form>
                        </>
                    }



                </Col>
            </Row>
        </Container >

    );

}

export default CreateAdvert;
