// // PhoneAuth.js


import React, { useState } from 'react';
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import { Container, Form, Button, Alert } from 'react-bootstrap'; // Import Bootstrap components
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import { firebaseConfig } from '../firebase'; // Your Firebase auth import

firebase.initializeApp(firebaseConfig);

const PhoneAuth = () => {

    const [verificationCode, setVerificationCode] = useState('');
    const [verificationId, setVerificationId] = useState('');
    const [isCodeSent, setIsCodeSent] = useState(false);
    const [error, setError] = useState('');
    const [isCaptchaVisible, setIsCaptchaVisible] = useState(true);
    const [reactPhonenumber, setReactPhoneNumber] = useState()
    const [email, setEmail] = useState('');
    const [confirmEmail, setConfirmEmail] = useState('');
    const [fullName, setFullName] = useState('');
    const [selectedCountry, setSelectedCountry] = useState('');


    const isValidEmail = (email) => {
        // Regular expression for a valid email format
        const emailRegex = /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/;
        return emailRegex.test(email);
    };

    const isValidFullName = (fullName) => {
        // Regular expression to check if fullName contains only letters and spaces
        const fullNameRegex = /^[A-Za-z\s]+$/;
        return fullNameRegex.test(fullName);
    };

    const handleSendCode = async () => {

        // fullName validation
        if (!fullName) {
            setError('Full Name is required');
            return true;
        } else if (!isValidFullName(fullName)) {
            setError('Full Name should not contain numbers or special characters');
            return true;
        }


        // Email validation
        if (!email) {
            setError('Email is required');
            return true;
        } else if (!isValidEmail(email)) {
            setError('Invalid email format');
            return true;
        } else if (email !== confirmEmail) {
            setError('Emails do not match');
            return true;
        }

        console.log(email, fullName, selectedCountry, reactPhonenumber)




        try {
            const appVerifier = new firebase.auth.RecaptchaVerifier('recaptcha-container');
            const confirmationResult = await firebase.auth().signInWithPhoneNumber('+' + reactPhonenumber, appVerifier);
            setVerificationId(confirmationResult.verificationId);
            setIsCodeSent(true);
            // Hide the CAPTCHA
            setIsCaptchaVisible(false);
        } catch (error) {
            setError('Error sending verification code: ' + error.message);
        }
    };


    // const handleVerifyCode = async () => {
    //     try {
    //         const credential = firebase.auth.PhoneAuthProvider.credential(verificationId, verificationCode);
    //         await firebase.auth().signInWithCredential(credential);

    //         // Once the user is signed in or registered, update Firestore with user data.
    //         const user = firebase.auth().currentUser;

    //         // Check if the user is already registered by looking up their UID in Firestore.
    //         const userDocRef = firebase.firestore().collection('users').doc(user.uid);
    //         const userDoc = await userDocRef.get();

    //         if (!userDoc.exists) {
    //             // If the user doesn't exist, register them.


    //         }

    //         console.log('Phone authentication successful');
    //     } catch (error) {
    //         setError('Error verifying code: ' + error.message);
    //     }
    // };

    const handlePhoneChange = (value, country) => {

        // Remove any non-numeric characters except for "+"
        setReactPhoneNumber(value)
        setSelectedCountry(country.countryCode.toUpperCase());
    };

    const handleVerifyCode = async () => {
        try {



            const credential = firebase.auth.PhoneAuthProvider.credential(verificationId, verificationCode);
            await firebase.auth().signInWithCredential(credential);

            // Once the user is signed in or registered, update Firestore with user data.
            const user = firebase.auth().currentUser;

            // Check if the user is already registered by looking up their UID in Firestore.
            const userDocRef = firebase.firestore().collection('users').doc(user.uid);

            // Set up a polling mechanism to wait for the user document to be created.
            const pollForUserDoc = async () => {
                const userDoc = await userDocRef.get();
                if (userDoc.exists) {
                    // User document is now available, you can update the phone number.
                    await userDocRef.update(
                        {
                            'profileStatus': true,
                            'name': fullName,
                            'phone': '+' + reactPhonenumber,
                            'country_name': selectedCountry,
                            'country_code':
                                selectedCountry,
                            'device': { name: 'desktop' },
                            'email': email
                        }
                    );

                    // You can display a success message to the user or redirect them to another page.
                    console.log('Phone authentication successful');
                } else {
                    // User document is not yet available, continue polling.
                    setTimeout(pollForUserDoc, 3000); // Poll every 3 seconds
                }
            };

            // Start polling for the user document.
            pollForUserDoc();
        } catch (error) {
            setError('Error verifying code: ' + error.message);
        }
    };


    return (
        <Container className="text-center mt-5">
            <h4>
                <i className="fa fa-lock"></i> Authentication
            </h4>
            <p>
                To begin, please provide your phone number for verification.<br />

                This service is available only in the UK, USA, Canada, and some EU countries.
            </p>

            {error && <Alert variant="danger">{error}</Alert>}

            <div style={{ maxWidth: '300px', margin: 'auto' }}>

                <div id="recaptcha-container" style={{ display: isCaptchaVisible ? 'block' : 'none', margin: 'auto' }}></div>

            </div>

            <Form className="mx-auto mt-3 " style={{ maxWidth: '300px' }}>
                {isCodeSent ? (
                    <>

                        <Form.Control
                            type="text"
                            pattern="[0-9]*"
                            inputMode="numeric"
                            placeholder="Enter 6-digit verification code"
                            value={verificationCode}
                            onChange={(e) => setVerificationCode(e.target.value)}
                            className="mb-2 text-center"
                            minLength="6"
                            maxLength="6"
                            required
                        />



                        <Button className='mt-4' variant="success" onClick={handleVerifyCode}>Verify Code</Button>
                    </>
                ) : (
                    <>
                        <Form.Group className="mb-3">
                            <Form.Control
                                type="text"
                                placeholder="Full Name"
                                value={fullName}
                                onChange={(e) => setFullName(e.target.value)}
                                required
                            />
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Control
                                type="email"
                                placeholder="Email Address"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                                required
                            />
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Control
                                type="email"
                                placeholder="Confirm Email Address"
                                value={confirmEmail}
                                onChange={(e) => setConfirmEmail(e.target.value)}
                                required
                            />
                        </Form.Group>
                        <PhoneInput
                            country={'us'} // Default country
                            className="text-left"
                            placeholder="Enter phone number"
                            value={reactPhonenumber}
                            onChange={(value, country) => handlePhoneChange(value, country)}
                        // onChange={setReactPhoneNumber}
                        />
                        {/* <Form.Control
                            type="text"
                            placeholder="Enter phone number"
                            value={phoneNumber}
                            onChange={handlePhoneNumberChange}
                            className="mb-2"
                        /> */}
                        <Button className='mt-4' variant="success" onClick={handleSendCode}>Send Code</Button>
                    </>
                )}

            </Form>
        </Container>
    );
};

export default PhoneAuth;

