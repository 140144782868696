import Navbar from './components/Navbar';
import Protected from './components/Protected';
import { AuthContextProvider } from './context/AuthContext';
import Account from './pages/Account';
import FindPets from './pages/FindPets';
import Home from './pages/Home';
import Signin from './pages/Signin';

import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import { auth } from './firebase';

import CreateAdvert from './components/CreateAdvert';
import BreedDetector from './pages/BreedDetector';
import PetAdvisor from './pages/PetAdvisor';
import VideeoPage from './pages/videos';
import Termsofuse from './pages/Termsofuse';
import PrivacyPolicy from './pages/PrivacyPolicy';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootswatch/dist/minty/bootstrap.min.css'; // Replace 'minty' with your desired Bootswatch theme



// TODO: Note: Replace ^[theme]^ (examples: darkly, slate, cosmo, spacelab, and superhero. See https://bootswatch.com for current theme names.)
import './App.css';

import ReactGA from "react-ga4";
ReactGA.initialize("TBHT1X4QEV");


function App() {

  const [currentUser, setCurrentUser] = useState(null);
  const [loading, setLoading] = useState(true);


  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(user => {
      setCurrentUser(user);
      setLoading(false);
    });

    // Cleanup subscription on unmount
    return () => unsubscribe();
  }, []);
  return (

    <AuthContextProvider>

      <Navbar />

      <Routes>

        <Route path='/' element={<Home />} />
        <Route path='/index.html' element={<Home />} />

        <Route path='/signin' element={<Signin />} />
        <Route
          path='/account'
          element={
            <Protected>
              <div className="container-fluid">
                <main role="main" className=" ">
                  <Account></Account>
                </main>
              </div>
            </Protected>
          }

        />

        <Route
          path='/findpets'
          element={

            <div className="container-fluid">
              <main role="main" className=" ">
                <FindPets />
              </main>
            </div>

          }

        />

        <Route
          path='/pet-advisor'
          element={

            <div className="container-fluid">
              <main role="main" className=" ">
                <PetAdvisor />
              </main>
            </div>

          }

        />

        <Route
          path='/breed-detector'
          element={

            <div className="container-fluid">
              <main role="main" className=" ">
                <BreedDetector />
              </main>
            </div>

          }

        />



        <Route
          path='/createadvert'
          element={
            <div className="container-fluid">
              <main role="main" className=" ">
                <CreateAdvert></CreateAdvert>
              </main>
            </div>
          }

        />

        <Route
          path='/videos'
          element={
            <div className="container-fluid">
              <main role="main" className=" ">
                <VideeoPage></VideeoPage>
              </main>
            </div>
          }

        />

        <Route
          path='/terms-of-use'
          element={
            <div className="container-fluid">
              <main role="main" className=" ">
                <Termsofuse></Termsofuse>
              </main>
            </div>
          }

        />

        <Route
          path='/privacy-policy'
          element={
            <div className="container-fluid">
              <main role="main" className=" ">
                <PrivacyPolicy></PrivacyPolicy>
              </main>
            </div>
          }

        />

        <Route path="*" element={<Navigate to="/" />} />
      </Routes>

    </AuthContextProvider >

  );
}

export default App;
